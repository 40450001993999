import React from 'react';
import PropTypes from 'prop-types';
import CallStatusShape from 'app/shapes/CallStatusShape';

import {
  Box,
  Typography,
} from '@mui/material';

import SanitizedHTML from 'app/helpers/SanitizedHTML';

const GenericStep = ({ callStatus, children }) => {
  return (
    <Box textAlign="center">
      <Typography variant="h3">
        <SanitizedHTML html={callStatus.message} />
      </Typography>
      { children }
    </Box>
  );
};

GenericStep.defaultProps = {
  children: null,
};

GenericStep.propTypes = {
  callStatus: CallStatusShape.isRequired,
  children: PropTypes.node,
};

export default GenericStep;
