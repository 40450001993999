import React from 'react';
import PropTypes from 'prop-types';
import { useGetCountriesQuery } from 'app/api/mainApi';
import AddPhone from '../components/availability/AddPhone';
import PhoneShape from 'app/shapes/PhoneShape';

const AddPhoneContainer = ({ currentUser, phones, setShowAddPhoneNumber, afterSave, afterCancel }) => {
  const { data: countries, isLoading, error } = useGetCountriesQuery();

  if (isLoading) {
    return <div>Loading phone countries...</div>;
  }

  if (error) {
    console.error('Error fetching phone countries:', error);
    return <div>Error loading phone countries. Please try again later.</div>;
  }

  return (
    <AddPhone
      currentUser={currentUser}
      phones={phones}
      setShowAddPhoneNumber={setShowAddPhoneNumber}
      countries={countries}
      afterSave={afterSave}
      afterCancel={afterCancel}
    />
  );
};

AddPhoneContainer.defaultProps = {
  currentUser: {},
  phones: [],
  afterSave: () => {},
  afterCancel: () => {},
};

AddPhoneContainer.propTypes = {
  currentUser: PropTypes.object,
  phones: PropTypes.arrayOf(PhoneShape),
  setShowAddPhoneNumber: PropTypes.func.isRequired,
  afterSave: PropTypes.func,
  afterCancel: PropTypes.func,
};

export default AddPhoneContainer;
