import React from 'react';
import { Link } from 'react-router-dom';

const SimpleHeader = () => {
  return (
    <header>
      <div className="container-max">
        <div className="container-header">
          <div className="row">
            <div className="col-sm-12 start-sm col-xs-12 center-xs">
              <div className="container-fluid">
                <div className="row start-sm middle-xs around-xs">
                  <div className="nfp-logo-container">
                    <Link
                      title="NiteFlirt"
                      reloadDocument
                      to="/"
                      role="link"
                      tabIndex={0}
                    >
                      <img src="https://niteflirt-assets.s3.amazonaws.com/homepage/niteflirt-logo-mob.png" alt="NiteFlirt" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SimpleHeader;
