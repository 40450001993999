import React from 'react';
import { PropTypes } from 'prop-types';

const BuyGoodyBagSuccessModal = ({ purchasedGoodyBagId }) => {
  return (
    <div>
      <div className="modal-header">
        <h4>Success! What Next?</h4>
      </div>

      <div className="modal-body" />

      <div className="modal-footer" style={{ justifyContent: 'center' }}>
        <a href={`/account/fileplayer?pgb=${purchasedGoodyBagId}&play=true`} className="btn">
          See files in File Player
        </a>
      </div>
    </div>
  );
};

BuyGoodyBagSuccessModal.propTypes = {
  purchasedGoodyBagId: PropTypes.number.isRequired,
};

export default BuyGoodyBagSuccessModal;
