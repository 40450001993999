import React from 'react';
import CallListingShape from 'app/shapes/CallListingShape';

import {
  Box,
  Typography,
} from '@mui/material';

import Connecting from 'app/components/call/callInProgress/Icons/Connecting';

const SeekerAnsweredStep = ({ listing }) => (
  <Box
    textAlign="center"
    mt={3}
  >
    <Typography variant="h3">
      You&#39;ve answered.
    </Typography>
    <Box mt={3} mb={3}>
      <Connecting />
    </Box>
    <Typography variant="body1" gutterBottom>
      Please&nbsp;
      <strong>press &quot;1&quot;</strong>
      &nbsp;on your phone to accept the call and be connected with&nbsp;
      {listing.login}
    </Typography>
  </Box>
);

SeekerAnsweredStep.propTypes = {
  listing: CallListingShape.isRequired,
};

export default SeekerAnsweredStep;
