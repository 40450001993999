import React, { useState } from 'react';

import MainHome from 'app/components/MainHome';
import { useGetHomepageListingsQuery } from 'app/api/mainApi';
import useFeaturedListingsContext from 'app/hooks/useFeaturedListingsContext';

const MainHomeContainer = () => {
  const [page, setPage] = useState(1);
  const { data: listingsData } = useGetHomepageListingsQuery({ page, perPage: 12 });
  const { FeaturedListingsContextProvider } = useFeaturedListingsContext({ listingsData });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <FeaturedListingsContextProvider>
      <MainHome
        currentPage={page}
        listingsData={listingsData}
        handlePageChange={handlePageChange}
      />
    </FeaturedListingsContextProvider>
  );
};

export default MainHomeContainer;
