export default class GTMUtility {
  // Check if GTM is initialized
  static isInitialized() {
    return Array.isArray(window.dataLayer);
  }

  // Send a custom event with attributes to GTM
  static sendEvent(eventName, attributes = {}) {
    if (this.isInitialized()) {
      window.dataLayer.push({
        event: eventName,
        ...attributes,
      });
    } else {
      console.debug('GTM is not initialized');
    }
  }
}

export function sendViewModalEvent(component) {
  const componentName = component?.displayName;
  if (componentName) {
    GTMUtility.sendEvent('view_modal', { modal_component: componentName });
  } else {
    console.warn('sendViewModalEvent called but could not determine component name');
  }
}
