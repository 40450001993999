import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useGetNewUserQuery } from 'app/api/mainApi';
import ReCAPTCHA from 'react-google-recaptcha';

const useRecaptcha = () => {

  const query = useGetNewUserQuery();
  const recaptchaSiteKey = query?.data?.recaptcha_site_key;
  const recaptchaRef = useRef();

  const recaptchaComponent = (onChange, onError) => {
    if (!recaptchaSiteKey) {
      console.error("Missing recaptcha_site_key");
      return null;
    }

    return <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={recaptchaSiteKey}
      size="invisible"
      onChange={(token) => onChange(token)}
      onError={() => onError()}
    />
  }
  const executeRecaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      recaptchaRef.current.execute();
    }
  }

  return {
    executeRecaptcha,
    recaptchaComponent,
  }
};

useRecaptcha.propTypes = {
  component: PropTypes.object.isRequired,
};

export default useRecaptcha;