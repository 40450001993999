import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { FormTextField } from 'app/components/form_components';
import PasswordMeter from 'app/components/account_info/PasswordMeter';
import { PasswordHelpText } from 'app/components/account_info/AccountInfoTexts';

const PasswordFields = ({ required }) => {
  const form = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const isPasswordFocused = true;
  const [hasFocusedPasswordConfirmation, setHasFocusedPasswordConfirmation] = useState(false);

  const showPasswordToggle = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={() => setShowPassword(!showPassword)} size="small">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  };

  const password = form.watch('password');
  const passwordConfirmation = form.watch('password_confirmation');

  const getPasswordError = () => {
    if (password) {
      if (password.length < 6 || password.length > 16) {
        return 'The password does not meet the requirements.';
      } else if (!/[A-Za-z]/.test(password) || !/[0-9]/.test(password)) {
        return 'The password does not meet the requirements.';
      } else {
        return null;
      }
    } else if (required || passwordConfirmation) {
      return 'Please enter a password that you will use to sign in.';
    } else {
      return null;
    }
  };

  const getPasswordConfirmationError = () => {
    if (passwordConfirmation) {
      if (password !== passwordConfirmation) {
        return 'Passwords do not match';
      }
      return null;
    }

    if (password || required) {
      return 'Please re-type your password.';
    }

    return null;
  };

  const updatePasswordConfirmationError = () => {
    if (hasFocusedPasswordConfirmation) {
      const error = getPasswordConfirmationError();
      if (error) {
        form.setError('password_confirmation', {
          type: 'manual',
          message: error,
        });
      } else {
        form.clearErrors('password_confirmation');
      }
    }
  };

  useEffect(() => {
    updatePasswordConfirmationError();
  }, [password, passwordConfirmation]);

  return (
    <Stack
      spacing={1}
      mb={3}
      sx={{ width: '100%', p: 0 }}
    >
      <FormTextField
        label="Enter new password"
        name="password"
        type={showPassword ? 'text' : 'password'}
        InputProps={showPasswordToggle}
        validation={{ validate: getPasswordError }}
        helperText=" "
      >
        {isPasswordFocused && <PasswordMeter password={password || ''} />}
      </FormTextField>
      {isPasswordFocused && <PasswordHelpText />}
      <FormTextField
        label="Retype Password"
        name="password_confirmation"
        type={showPassword ? 'text' : 'password'}
        InputProps={showPasswordToggle}
        onFocus={() => setHasFocusedPasswordConfirmation(true)}
        onBlur={updatePasswordConfirmationError}
        validation={{ validate: getPasswordConfirmationError }}
      />
    </Stack>
  );
};

PasswordFields.propTypes = {
  required: PropTypes.bool,
};

PasswordFields.defaultProps = {
  required: true,
};

export default PasswordFields;
