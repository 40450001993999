import React from 'react';
import { PropTypes } from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

import PhoneListingContainer from 'app/containers/PhoneListingContainer';
import Hero from 'app/components/Hero';
import TollFreeAdvertContainer from 'app/containers/TollFreeAdvertContainer';
import StayConnected from './StayConnected';
import NumberlessPagination from './NumberlessPagination';

const MainHome = ({
  currentPage,
  listingsData,
  handlePageChange,
}) => {
  if (!listingsData || !listingsData.listings) {
    return null;
  }

  return (
    <>
      <section className="body-content">
        <Hero />
        <Box
          px={{ xs: 0, sm: 2 }}
          pt={{ xs: 2.5, sm: 3.5 }}
          pb={{ xs: 10, sm: 10 }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: { xs: '3px', sm: '3px' },
              justifyContent: 'center',
            }}
          >
            {listingsData?.listings.map((listing, i) => {
              return (
                <Box
                  key={`listing_${listing.id}`}
                  sx={{
                    width: { xs: '330px', sm: '330px' },
                    flex: { xs: '0 0 330px', sm: '0 0 330px' },
                  }}
                >
                  <PhoneListingContainer
                    listing={listing}
                    indexInPage={i}
                    isQualifiableFeaturedListingContext
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Grid container sx={{ mt: 1, pr: 1 }}>
          <Grid item xs={12} container justifyContent="flex-end">
            <NumberlessPagination
              hasNext={listingsData.has_next}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Grid>
        </Grid>
        <Box sx={{
          maxWidth: '350px',
          margin: '20px auto',
          '@media (min-width: 1200px)': {
            display: 'none', // This hides the Box on screens larger than 1200px
          },
        }}
        >
          <TollFreeAdvertContainer />
          <StayConnected />
        </Box>
      </section>
      <aside>
        <TollFreeAdvertContainer />
        <StayConnected />
      </aside>
    </>
  );
};

MainHome.defaultProps = {
  listingsData: [],
  currentPage: 1,
};

MainHome.propTypes = {
  currentPage: PropTypes.number,
  listingsData: PropTypes.array,
  handlePageChange: PropTypes.func.isRequired,
};

export default MainHome;
