import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import DialogsContext from 'app/contexts/DialogsContext';

import AddPhoneContainer from 'app/containers/AddPhoneContainer';

const AddPhoneDialog = ({
  title,
  currentUser,
  phones,
}) => {
  const { closeDialog } = useContext(DialogsContext);
  return (
    <>
      <DialogTitle>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        We&apos;ll call you at the number you enter below.
        <AddPhoneContainer
          currentUser={currentUser}
          phones={phones}
          afterSave={closeDialog}
          afterCancel={closeDialog}
          setShowAddPhoneNumber={() => {}}
        />
        Your number is never revealed.
      </DialogContent>
    </>
  )
};

AddPhoneDialog.propTypes = {
  title: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  phones: PropTypes.array.isRequired,
};

export default AddPhoneDialog;
