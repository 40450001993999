import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Route, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Box, Grid, Tabs, Tab, styled } from '@mui/material';
import AccountInfoContainer from 'app/containers/account_info/AccountInfoContainer';
import PasswordSecurityContainer from 'app/containers/account_info/PasswordSecurityContainer';
import IdVerificationContainer from 'app/containers/account_info/IdVerificationContainer';
import CloseAccountContainer from 'app/containers/account_info/CloseAccountContainer';
import { useGetUserQuery } from 'app/api/mainApi';
import NotificationsContainer from 'app/containers/account_info/NotificationsContainer';
import { featureFlag } from 'app/util/featureFlag';
import BehindSecondaryAuth from 'app/containers/BehindSecondaryAuth';

const PERSONAL_INFO_TABS = [
  { index: 0, title: 'Account Info', path: 'account_info', component: AccountInfoContainer },
  { index: 1, title: 'Password and Security', path: 'security', component: PasswordSecurityContainer },
  { index: 2, title: 'ID Verification', path: 'verification_status', component: IdVerificationContainer, hidden: true },
  { index: 3, title: 'Push Notifications', path: 'notifications', component: NotificationsContainer },
  { index: 4, title: 'Close Account', path: 'close', component: CloseAccountContainer, hidden: true },
];

export const PersonalInfoRoutes = () => {
  return PERSONAL_INFO_TABS.map((tab) => (
    <Route
      key={tab.path}
      path={tab.path}
      element={(
        <BehindSecondaryAuth>
          <PersonalInfoPages title={tab.title} index={tab.index}>
            <tab.component />
          </PersonalInfoPages>
        </BehindSecondaryAuth>
      )}
    />
  ));
};

const BoxSupportContent = styled(Box)(({ theme }) => ({
  '&.MuiBox-root': {
    flexGrow: 1,
    minHeight: '100vh',
    fontSize: '0.875em',
    lineHeight: '1.625em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.938em',
      lineHeight: '1.688em',
    },
  },
}));

const PersonalInfoPages = ({ children, title, index }) => {
  // The Account Info page is unique in that, as the PersonalInfoPages landing page,
  //  in the mobile view, it should start with the nav in front
  //  instead of the content.
  const [searchParams] = useSearchParams();
  const deepLinked = searchParams.get('deepLinked');
  const onFirstPersonalInfoPagesPage = index === 0;
  const [contentInFront, setContentInFront] = useState(!onFirstPersonalInfoPagesPage || deepLinked);
  const navigate = useNavigate();

  const { data } = useGetUserQuery();
  const idvStatus = data?.user?.idv_status;

  if (idvStatus !== undefined && (idvStatus !== 'not_required' && idvStatus !== 'exempt')) {
    PERSONAL_INFO_TABS[2].hidden = false;
  }

  if (!featureFlag.enabled('PUSH_NOTIFICATIONS_20261')) {
    PERSONAL_INFO_TABS[3].hidden = true;
  }

  useEffect(() => {
    // we have to do this to both elements. only one of them matters - but which one depends on screen size.
    document.getElementById('PersonalInfoPagesContent').scrollTo(0, 0);
    document.getElementById('PersonalInfoPagesContainer').scrollTo(0, 0);
  }, [index]);

  const loadTab = ({ path }) => {
    setContentInFront(true);
    navigate(`/account/${path}`);
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Box id="PersonalInfoPagesContainer" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={5} md={4} lg={3} style={{ borderRight: '1px solid var(--color_ddd)' }} className={`pos__Menu ${contentInFront ? 'zIndexBehind' : 'zIndexFront'}`}>
          <Box sx={{ position: { sm: 'sticky', xs: 'block' }, top: { sm: '0', xs: 'auto' } }}>
            <Box className="subnavigation-tabs" pb={{ xs: 10, sm: 0 }}>
              <Box className="subnavHeader">
                Personal Info
              </Box>
              <Tabs value={index} aria-label="PersonalInfoPages Pages" orientation="vertical" TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}>
                {PERSONAL_INFO_TABS.filter(tab => !tab.hidden).map((tab) => (
                  <Tab
                    key={tab.path}
                    label={tab.title}
                    value={tab.index}
                    onClick={() => loadTab(tab)}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
        </Grid>
        <Grid id="PersonalInfoPagesContent" item xs={12} sm={7} md={8} lg={9} sx={{ overflow: 'auto', borderRight: { sm: '1px solid var(--color_ddd)', xs: 'none' } }} className={`pos__Body ${contentInFront ? 'zIndexFront' : 'zIndexBehind'}`}>
          <div className="container-hdr-sticky2">
            <div className="row middle-xs start-xs nowrap-xs">
              <div className="col-xs-12">
                <div className="nav-icon-sticky">
                  <a href="#" onClick={() => setContentInFront(false)}>
                    <img src="/plus_frontend/assets/svg/ico-back-arrow.svg" alt="Back" className="mobile__arrow" />
                  </a>
                  &nbsp;
                </div>
                <h2>{ title }</h2>
              </div>
            </div>
          </div>
          <BoxSupportContent px={{ xs: 3, sm: 5 }} pt={{ xs: 5, sm: 4 }} pb={{ xs: 20, sm: 15 }}>
            { children }
          </BoxSupportContent>
        </Grid>
      </Grid>
    </Box>
  );
};

PersonalInfoPages.defaultProps = {
};

PersonalInfoPages.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default PersonalInfoPages;
