import { styled } from '@mui/material/styles';

const RatingContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '5px',
  '& a': {
    color: '#444',
    textDecoration: 'none',
    '&:hover': {
      color: '#06c',
    },
  },
});

export default RatingContainer;
