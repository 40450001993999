/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    text: [],
  },
  sort: '4', // Newest Arrivals
  category: '839', // Women (default)
  resultCounts: {},
};

const chatProfileFilterSlice = createSlice({
  name: 'chatProfileFilter',
  initialState,
  reducers: {
    clearAllButText: (state) => {
      return {
        ...initialState,
        filters: {
          ...initialState.filters,
          text: state.filters.text,
        },
      };
    },

    clearAllButCategory: (state) => {
      return {
        ...initialState,
        category: state.category,
      };
    },

    setText: (state, action) => {
      state.filters.text = action.payload;
    },

    setFilter: (state, action) => {
      state.filters[action.payload.filter] = action.payload.value;
    },

    setResultCounts: (state, action) => {
      state.resultCounts = action.payload;
    },

    setCategory: (state, action) => {
      state.category = action.payload;
    },
  },
});

const selectors = {
  filtersSelector: (state) => state.chatProfileFilter.filters,
  resultCountsSelector: (state) => state.chatProfileFilter.resultCounts,
  categorySelector: (state) => state.chatProfileFilter.category,
};

export default chatProfileFilterSlice;

export const {
  clearAllButText,
  clearAllButCategory,
  setCategory,
  setFilter,
  setResultCounts,
  setText,
} = chatProfileFilterSlice.actions;

export const {
  categorySelector,
  filtersSelector,
  resultCountsSelector,
} = selectors;
