import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Unstable_Grid2';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import PhoneListingContainer from 'app/containers/PhoneListingContainer';
import ChatListingContainer from 'app/containers/ChatListingContainer';

const CategoryListings = ({
  currentTab,
  currentSort,
  handleTabChange,
  handleSortChange,
  listingsData,
  sorts,
  tabs,
  showHeader,
}) => {
  return (
    <>
      {showHeader && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <Tabs value={currentTab} onChange={handleTabChange}>
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={3} container justifyContent="flex-end">
            {currentTab !== 3 && (
              <FormControl sx={{ zIndex: 10, minWidth: 120 }}>
                <InputLabel id="sort-by-label">Sort by</InputLabel>
                <Select
                  labelId="sort-by-label"
                  value={currentSort}
                  label="Sort by"
                  onChange={handleSortChange}
                >
                  {sorts.map((sort) => (
                    <MenuItem key={sort.value} value={sort.value}>
                      {sort.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
      )}
      <Box
        px={{ xs: 0, sm: 2 }}
        pt={{ xs: 2.5, sm: 3.5 }}
        pb={{ xs: 10, sm: 10 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: { xs: '3px', sm: '3px' },
            justifyContent: 'center',
          }}
        >
          {listingsData?.listings.map((listing, i) => (
            <Box
              key={`listing_${listing.id}`}
              sx={{
                width: { xs: '330px', sm: '330px' },
                flex: { xs: '0 0 330px', sm: '0 0 330px' },
              }}
            >
              {listingsData.chat ? (
                <ChatListingContainer advisor={listing} />
              ) : (
                <PhoneListingContainer
                  listing={listing}
                  indexInPage={i}
                  isQualifiableFeaturedListingContext
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

CategoryListings.defaultProps = {
  listingsData: null,
  currentSort: 0,
  showHeader: true,
  currentTab: 0,
  handleTabChange: () => {},
  handleSortChange: () => {},
  tabs: [],
  sorts: [],
};

CategoryListings.propTypes = {
  currentTab: PropTypes.number,
  handleTabChange: PropTypes.func,
  handleSortChange: PropTypes.func,
  listingsData: PropTypes.object,
  currentSort: PropTypes.number,
  sorts: PropTypes.array,
  tabs: PropTypes.array,
  showHeader: PropTypes.bool,
};

export default CategoryListings;
