import React from 'react';
import { PropTypes } from 'prop-types';
import AdvisorCenterNav from './AdvisorCenterNav';
import CategoryListing from './CategoryListing';

import { useGetAdvisorQuery } from 'app/api/mainApi';
import useProfileImages from 'app/hooks/useProfileImages';

import AdvisorAvailabilityMenuContainer from 'app/containers/AdvisorAvailabilityMenuContainer';
import UnreadMailBadge from 'app/components/shared/UnreadMailBadge';

// Use this if you have a link to a page that requires secondary auth
// import SecondaryAuthLink from 'app/components/SecondaryAuthLink';

import { featureFlag } from 'app/util/featureFlag';
import useSignOut from 'app/hooks/useSignOut';

const AdvisorExpandedNav = ({
  isExpanded,
  toggleExpandedNav,
  currentUser,
  closeAndNavigateTo,
  addMoneyUrl,
  balance,
}) => {
  const display = isExpanded ? 'block' : 'none';
  const { data: advisor } = useGetAdvisorQuery({ login: currentUser.login });
  const { acceptedAvatarImage: avatarImage } = useProfileImages(advisor);
  const isAvailabilityEnabled = featureFlag.enabled('AVAILABILITY_19613');
  const { signOut } = useSignOut();

  return (
    <>
      <div
        className="more-menu-bg"
        role="link"
        tabIndex={0}
        onClick={toggleExpandedNav}
        style={{ display }}
      >
        &nbsp;
      </div>
      <div className="more-menu" data-test-id="more-menu" style={{ display }}>
        <div className="row end-xs margin10 sticky" style={{ zIndex: '10' }}>
          <a className="menu-close" role="link" tabIndex={0} onClick={toggleExpandedNav} style={{ background: 'transparent' }}>
            <img src="/plus_frontend/assets/svg/x-close.svg" className="modal-close" alt="close" />
          </a>
        </div>
        <ul
          className="nav-top paddingtop0"
          style={{ display, zIndex: '1' }}
        >
          <li>
            <div
              className="menu-initials menu-avatar"
              style={{ backgroundImage: `url('${avatarImage}')` }}
            >
              &nbsp;
            </div>
          </li>
          <li className="margintopminus10">
            <a
              title={currentUser?.login}
              role="link"
              tabIndex={0}
              className="bluelink"
              data-test-id="menu-user-link"
              onClick={() => {
                closeAndNavigateTo(`/${currentUser?.login}`, true);
              }}
            >
              {currentUser?.login}
            </a>
          </li>
          {isAvailabilityEnabled && (
            <li>
              <AdvisorAvailabilityMenuContainer onClick={toggleExpandedNav} />
            </li>
          )}
          <li className="delin subhead marginbot0" />
          <li>
            <div className="small-text">
              Balance:&nbsp;
              <span>
                $
                {balance}
              </span>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a
                title="Transactions"
                role="link"
                tabIndex={0}
                className="bluelink"
                onClick={() => {
                  closeAndNavigateTo('/transactions/details', false);
                }}
              >
                Transactions
              </a>
            </div>
          </li>
          <li className="delin subhead marginbot0" />
          <li>
            <UnreadMailBadge />
          </li>
          <li>
            <a
              title="Featured Listings"
              role="link"
              tabIndex={0}
              onClick={() => {
                closeAndNavigateTo('/account/featured_listing_results/listings', true);
              }}
            >
              <img src="/plus_frontend/assets/svg/ico-featuredlistings.svg" alt="Featured Listings" className="nav-icon" />
              <span className="nav-text">Featured Listings</span>
            </a>
          </li>
          <li>
            <a
              title="Goodies"
              role="link"
              tabIndex={0}
              onClick={() => {
                closeAndNavigateTo('/account/filemanager#tab-goody', true);
              }}
            >
              <img src="/plus_frontend/assets/svg/ico-goodies2.svg" alt="Goodies" className="nav-icon" />
              <span className="nav-text">Goodies</span>
            </a>
          </li>
          <li>
            <a
              title="Customers"
              role="link"
              tabIndex={0}
              onClick={() => {
                closeAndNavigateTo('/my_customers', true);
              }}
            >
              <img src="/plus_frontend/assets/svg/ico-customers.svg" alt="Customers" className="nav-icon" />
              <span className="nav-text">Customers</span>
            </a>
          </li>
          <li>
            <a
              title="File Manager"
              role="link"
              tabIndex={0}
              onClick={() => {
                closeAndNavigateTo('/account/filemanager#tab-library', true);
              }}
            >
              <img src="/plus_frontend/assets/svg/ico-filemanager.svg" alt="File Manger" className="nav-icon" />
              <span className="nav-text">File Manager</span>
            </a>
          </li>
          <li className="delin subhead marginbot0" />
          <AdvisorCenterNav
            balance={balance}
            closeAndNavigateTo={closeAndNavigateTo}
            currentUser={currentUser}
            addMoneyUrl={addMoneyUrl}
          />
          <li className="delin">
            <CategoryListing closeAndNavigateTo={closeAndNavigateTo} />
          </li>
          <li className="delin">
            <a
              role="link"
              tabIndex={0}
              title="Help & Support"
              onClick={() => closeAndNavigateTo('//support.niteflirt.com/hc/en-us', true)}
            >
              <img
                src="/plus_frontend/assets/svg/ico-help1.svg"
                alt="Help & Support"
                className="nav-icon"
              />
              <span className="nav-text">Help & Support</span>
            </a>
          </li>

          <li>
            <a
              role="link"
              tabIndex={0}
              title="About"
              onClick={() => closeAndNavigateTo('/help/about', false)}
            >
              <img
                src="/plus_frontend/assets/svg/ico-nf.svg"
                alt="About"
                className="nav-icon"
              />
              <span className="nav-text">About</span>
            </a>
          </li>

          {currentUser && (
            <>
              <li className="delin subhead marginbot0" />
              <li>
                <a
                  title="Sign Out"
                  onClick={signOut}
                  role="link"
                  tabIndex={0}
                >
                  <img src="/plus_frontend/assets/svg/ico-logout.svg" alt="Sign Out" className="nav-icon" />
                  <span className="nav-text">Sign Out</span>
                </a>
              </li>
            </>
          )}
        </ul>
        <div className="more-menu-bottom-padding" />
      </div>
    </>
  );
};

AdvisorExpandedNav.defaultProps = {
  currentUser: null,
  balance: null,
};

AdvisorExpandedNav.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  toggleExpandedNav: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  closeAndNavigateTo: PropTypes.func.isRequired,
  addMoneyUrl: PropTypes.string.isRequired,
  balance: PropTypes.string,
};

export default AdvisorExpandedNav;
