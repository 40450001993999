import React, { useState, useContext } from 'react';

import GenericModal from 'app/components/modals/GenericModal';
import ModalsContext from 'app/contexts/ModalsContext';

// Post Actions Hooks
import { usePinGoodyBagMutation, useUnpinGoodyBagMutation, useDeleteGoodyBagMutation } from 'app/api/assetApi';

const useGoodyBagMenuActions = ({
  goodyBag,
  currentUser,
  advisor,
}) => {
  const [ unpinGoodyBag ] = useUnpinGoodyBagMutation();
  const [ pinGoodyBag ] = usePinGoodyBagMutation();
  const [ deleteGoodyBag ] = useDeleteGoodyBagMutation();
  const [copyLinkText, setCopyLinkText] = useState('Share');
  const viewingSelf = (currentUser?.id === advisor?.id);
  const { openModal } = useContext(ModalsContext);

  const goodyBagActions = [];

  if (viewingSelf) {
    if (goodyBag.pinned) {
      goodyBagActions.push({
        label: 'Unpin',
        onClick: (toggleTip) => {
          unpinGoodyBag({ goodyBagId: goodyBag.id });
          toggleTip();
        },
      });
    } else {
      goodyBagActions.push({
        label: 'Pin',
        onClick: (toggleTip) => {
          pinGoodyBag({ goodyBagId: goodyBag.id });
          toggleTip();
        },
      });
    }

    goodyBagActions.push({
      label: copyLinkText,
      onClick: (toggleTip) => {
        navigator.clipboard.writeText(`https://${window.location.host}/${encodeURIComponent(advisor.login)}/goodies/${goodyBag.id}`);
        setCopyLinkText('Copied!');
        setTimeout(() => {
          toggleTip();
          setCopyLinkText('Share');
        }, 1000);
      },
    });

    goodyBagActions.push({
      label: 'Edit',
      onClick: (toggleTip) => {
        toggleTip();
        window.location.href = '/account/filemanager#tab-goody';
      },
    });

    const deletePostBody = 'Are you sure you want to delete this Goody Bag?';
    goodyBagActions.push({
      label: 'Delete',
      onClick: (toggleTip) => {
        openModal({
          component: GenericModal,
          props: {
            callToAction: () => {
              deleteGoodyBag({ goodyBagId: goodyBag.id });
            },
            ctaButtonLabel: 'Delete Goody Bag',
            header: 'Delete Goody Bag?',
            body: deletePostBody,
          },
        });
        toggleTip();
      },
    });
  }

  return {
    goodyBagActions,
  };
};

export default useGoodyBagMenuActions;
