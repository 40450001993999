import React from 'react';
import PropTypes from 'prop-types';

import AvailabilityShape from 'app/shapes/AvailabilityShape';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import EnrollInChatCTA from 'app/components/availability/EnrollInChatCTA';
import ChatStatusMenuItem from 'app/components/availabilityMenu/ChatStatusMenuItem';

import { useUpdateAvailabilityMutation } from 'app/api/mainApi';
import PhoneShape from 'app/shapes/PhoneShape';

const ListAvailability = styled(List)(() => ({
  '&.MuiList-root': {
    fontSize: '0.850em',
    padding: '0 0px',
    '& .MuiListItemIcon-root': {
      display: 'none',
    },
    '& .Mui-selected': {
      '.MuiListItemIcon-root': {
        display: 'block',
      },
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: '#99c',
    },
    '& .MuiButtonBase-root': {
      borderRadius: '0px',
      padding: '3px 15px',
    },
    '& .MuiDivider-root': {
      margin: '3px 0px',
    },
    '& .MuiSvgIcon-root': {
      height: '16px',
    },
  },
}));

const ChatStatusMenu = ({
  enrolledInChat,
  phone,
  chatStatuses,
  explicitlyUnenrolledInChat,
  chatProfileId,
  availability
}) => {
  const [ updateCurrentChatPhoneStatus ] = useUpdateAvailabilityMutation();

  const handleListItemClick = (chatStatus) => {
    if (chatStatus === phone.chat_status) { return; }

    updateCurrentChatPhoneStatus({ availability: { chat_status: chatStatus } });
  };
  const onlyHasInternationalPhones = availability?.phones?.every(phone => phone.domestic !== true) && availability?.phones?.length;

  let content;
  if (!enrolledInChat || explicitlyUnenrolledInChat) {
    content = (<EnrollInChatCTA chatProfileId={chatProfileId} explicitlyUnenrolledInChat={!!explicitlyUnenrolledInChat} />);
  } else if (onlyHasInternationalPhones) {
    const handleStatusChangeWithNoPhone = (chatStatus) => {
      if (availability.chatStatus === chatStatus) { return; }
      updateCurrentChatPhoneStatus({ availability: { chatStatus, phoneId: null } });
    };

    content = (
      <Paper sx={{ p: 0 }} variant="outlined">
        <ListAvailability aria-label="section1">
          { chatStatuses.map((chatStatus) => (
            <ChatStatusMenuItem
              key={chatStatus.value}
              onClick={() => { handleStatusChangeWithNoPhone(chatStatus.value); }}
              selected={chatStatus.value === availability.chat_status}
              primaryText={chatStatus.title}
            />
          ))}
        </ListAvailability>
      </Paper>
    );
  } else if (!phone || Object.keys(phone).length === 0) {
    content = (
      <Paper sx={{ p: 2 }} variant="outlined">
        No verified chat phone number set.
      </Paper>
    );
  } else {
    content = (
      <Paper sx={{ p: 0 }} variant="outlined">
        <ListAvailability aria-label="section1">
          {chatStatuses[ 0 ] && (
            <ChatStatusMenuItem
              onClick={() => { handleListItemClick(chatStatuses[ 0 ].value); }}
              selected={chatStatuses[ 0 ].value === phone.chat_status}
              primaryText={chatStatuses[ 0 ].title}
              secondaryText={phone.secure_number}
              data-test-id={`chat-status-menu-item-${chatStatuses[ 0 ].title}`}
            />
          )}
          <Divider />
          {chatStatuses[ 1 ] && (
            <ChatStatusMenuItem
              onClick={() => { handleListItemClick(chatStatuses[ 1 ].value); }}
              selected={chatStatuses[ 1 ].value === phone.chat_status}
              primaryText={chatStatuses[ 1 ].title}
            />
          )}
          <Divider />
          {chatStatuses[ 2 ] && (
            <ChatStatusMenuItem
              onClick={() => { handleListItemClick(chatStatuses[ 2 ].value); }}
              selected={chatStatuses[ 2 ].value === phone.chat_status}
              primaryText={chatStatuses[ 2 ].title}
            />
          )}
        </ListAvailability>
      </Paper>
    );
  }

  return (
    <Box data-test-id="chat-status-menu">
      <Grid container sx={{ my: 1, alignItems: 'center', px: { xs: 0, sm: 0 } }}>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            Chat Status
          </Typography>
        </Grid>
      </Grid>
      {content}
    </Box>
  );
};

ChatStatusMenu.defaultProps = {
  phone: {},
  chatStatuses: [],
  enrolledInChat: false,
  explicitlyUnenrolledInChat: false,
  chatProfileId: null,
  availability: {},
};

ChatStatusMenu.propTypes = {
  phone: PhoneShape,
  enrolledInChat: PropTypes.bool,
  chatStatuses: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  })),
  explicitlyUnenrolledInChat: PropTypes.bool,
  chatProfileId: PropTypes.number,
  availability: AvailabilityShape,
};

export default ChatStatusMenu;
