import React, { useEffect, useState } from 'react';
import { Box, Grid, Container, TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import AppBody from 'app/components/layout/AppBody';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import GenderSelectChips from 'app/components/GenderSelectChips';
import ChatTabs from 'app/components/chat/ChatTabs';
import ChatBrowseResults from 'app/components/chat/ChatBrowseResults';
import NumberlessPagination from 'app/components/NumberlessPagination';

import { useChatProfileSearchMutation, useGetAppConfigQuery } from 'app/api/mainApi';
import {
  setCategory,
  setText,
  clearAllButText,
  clearAllButCategory,
  categorySelector,
  filtersSelector,
  setResultCounts,
} from 'app/slices/chatProfileFilterSlice';

const ChatTabsPos = styled(Box)(() => ({
  '&.MuiBox-root': {
    marginTop: '18px',
    borderTop: '1px solid #e0e0e0',
  },
}));

const ChatBrowseContainer = () => {
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const category = useSelector(categorySelector);
  const filters = useSelector(filtersSelector);
  const { text } = filters;

  const [
    chatProfileSearch,
    {
      data: chatProfileSearchResults,
      isSuccess: chatProfileSearchResultsSuccess,
      isLoading: chatProfileSearchResultsLoading,
    },
  ] = useChatProfileSearchMutation();

  const triggerChatProfileSearch = () => {
    chatProfileSearch({ ...filters, category, page });
  };

  const handleCategoryChange = (category) => {
    setPage(1);
    dispatch(clearAllButText());
    dispatch(setCategory(category));
  };

  const handleTextChange = (evt) => {
    dispatch(setText(evt.target.value));
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (chatProfileSearchResultsSuccess && chatProfileSearchResults) {
      dispatch(setResultCounts(chatProfileSearchResults.facet));
    }
  }, [chatProfileSearchResultsSuccess]);

  // clear filters on page load except for category
  useEffect(() => {
    dispatch(clearAllButCategory());
  }, []);

  // trigger a search when the filters change
  useEffect(() => {
    const debouncedFunction = debounce(triggerChatProfileSearch, 500);
    debouncedFunction();
    return debouncedFunction.cancel;
  }, [filters, category, page]);

  // save facet/filter count data when it is returned along with results
  useEffect(() => {
    if (chatProfileSearchResultsSuccess && chatProfileSearchResults) {
      dispatch(setResultCounts(chatProfileSearchResults.facet));
    }
  }, [chatProfileSearchResultsSuccess]);

  return (
    <AppBody>
      <TemplateShell2 header="Chat" goBackPath="/">
        <Container sx={{ mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Find Flirts to chat with"
                variant="outlined"
                value={text}
                onChange={handleTextChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {text && (
                        <IconButton onClick={() => dispatch(setText(''))}>
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <GenderSelectChips state={category} setState={handleCategoryChange} />
            </Grid>
          </Grid>
        </Container>
        <ChatTabsPos>
          <ChatTabs activeTab="browse" currentUser={currentUser} />
        </ChatTabsPos>
        {chatProfileSearchResults?.chat_profiles && (
          <>
            <ChatBrowseResults results={chatProfileSearchResults.chat_profiles} />
            {(chatProfileSearchResults?.has_next || chatProfileSearchResults?.has_previous) && (
              <NumberlessPagination
                hasNext={chatProfileSearchResults?.has_next}
                page={chatProfileSearchResults?.current_page}
                onChange={handlePageChange}
              />
            )}
          </>
        )}
        {chatProfileSearchResultsLoading && (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <h2>Loading...</h2>
          </Box>
        )}
        {text && chatProfileSearchResults?.chat_profiles.length < 1 && !chatProfileSearchResultsLoading && (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <h2>No results found</h2>
            <p>
              We can't find any results based on your search.
              <br />
              {' '}
              Please try again.
            </p>
          </Box>
        )}

      </TemplateShell2>
    </AppBody>
  );
};

export default ChatBrowseContainer;
