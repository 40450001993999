import React from 'react';

import ListingShape from 'app/shapes/ListingShape';

import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

const BlockedByViewerError = ({ listing }) => {
  return (
    <Grid
      item
      xs={12}
      mt={2}
      mb={1}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        mt={3}
        justifyContent="center"
        sx={{ textAlign: 'center' }}
      >
        <Box
          sx={{
            width: { sm: '380px' },
            textAlign: { xs: 'center', sm: 'left'},
            mb: { xs: 2, sm: 0 },
          }}
        >
          <Typography variant="error">
            We are not able to place this call because you have blocked&nbsp;
            {listing.login}
            .
          </Typography>
        </Box>
        <Box>
          <a href={listing.listing_category_path}>
            <Button
              variant="outlined"
              size="medium"
            >
              Find a Flirt
            </Button>
          </a>
        </Box>
      </Stack>
    </Grid>
  );
};

BlockedByViewerError.defaultProps = {
};

BlockedByViewerError.propTypes = {
  listing: ListingShape.isRequired,
};

export default BlockedByViewerError;
