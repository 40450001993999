import React from 'react';
import { PropTypes } from 'prop-types';
import ReactTimeAgo from 'react-time-ago';
import { DateTime } from 'luxon';

import CommenterNameTooltip from 'app/components/tooltips/CommenterNameTooltip';
import useProfileImages from 'app/hooks/useProfileImages';
import AuthenticatedLink from '../shared/AuthenticatedLink';

const Comment = ({ comment, advisor, currentUser }) => {
  const { acceptedAvatarImage: avatarImage } = useProfileImages(advisor);
  const avatar = comment.by_post_author ?
    (
      <div
        className="col-xs-2 comments-avatar orig-poster"
        style={{ backgroundImage: `url('${avatarImage}')` }}
      />
    ) : (
      <div className="col-xs-2 comments-initials">
        {comment.poster_initials}
      </div>
    );

  const wrappedAvatar = comment.poster_login ? (
    <CommenterNameTooltip posterLogin={comment.poster_login}>
      {avatar}
    </CommenterNameTooltip>
  ) : (
    <AuthenticatedLink currentUser={currentUser} onClick={() => {}}>
      {avatar}
    </AuthenticatedLink>
  );

  return (
    <div
      data-id={comment.id}
      key={`comment_${comment.id}`}
      className="container-fluid container-comments"
    >
      <div className="row">
        <div className="col-xs-11">
          <div className="container-fluid padding0">
            <div className="row nowrap-xs start-xs">
              {wrappedAvatar}
              <div className="col-xs-10">
                <div className="comments-text">
                  {comment.body}
                </div>
                <div className="comments-time">
                  <ReactTimeAgo
                    date={DateTime.fromISO(comment.ordering_date).valueOf()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Comment.defaultProps = {
  currentUser: null,
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  advisor: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
};

export default Comment;
