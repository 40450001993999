import React from 'react';
import PropTypes from 'prop-types';

import { featureFlag } from 'app/util/featureFlag';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import StorefrontBackArrow from 'app/components/storefront/StorefrontBackArrow';
import ProfileMenu from 'app/components/ProfileMenu';
import KebobMenuContainer from 'app/containers/storefront/KebobMenuContainer';

const ProfilePageControls = ({ advisor, currentUser, darkAssets }) => {
  // TODO: Get rid of classes
  return (
    <Box p={1}>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item ml={1}>
          <StorefrontBackArrow />
        </Grid>
        <Grid item mr={1}>
          {featureFlag.enabled('FEED_TO_STOREFRONT_20571') ? (
            <KebobMenuContainer
              advisor={advisor}
              currentUser={currentUser}
              darkAssets={darkAssets}
            />
          ) : (
            <ProfileMenu advisor={advisor} currentUser={currentUser} darkAssets={darkAssets} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

ProfilePageControls.defaultProps = {
  advisor: null,
  currentUser: null,
  darkAssets: false,
};

ProfilePageControls.propTypes = {
  advisor: PropTypes.object,
  currentUser: PropTypes.object,
  darkAssets: PropTypes.bool,
};

export default ProfilePageControls;
