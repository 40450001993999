import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Box, IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Link } from 'react-router-dom';

import {
  useGetPublicPackagesForAdvisorQuery,
  useGetActiveTributesForAdvisorQuery,
} from 'app/api/mainApi';
import PackagesContainer from './PackagesContainer';

const FeedPackagesContainer = ({ advisor }) => {
  const { data: packages } = useGetPublicPackagesForAdvisorQuery({ advisorId: advisor?.id });
  const { data: activeTributes } = useGetActiveTributesForAdvisorQuery({ advisorLogin: advisor?.login });
  const lowestPrice = packages?.find(Boolean)?.rate;
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (event) => {
    event.preventDefault();
    setExpanded((prev) => !prev);
  };

  if (packages?.length < 1 || activeTributes?.packageTribute) return null;

  return (
    <Container sx={{ mt: 2, mb: 2 }}>
      <Box>
        <strong>Want full Feed access?</strong>
      &nbsp;
        <Link to="#" onClick={handleToggle}>Explore packages</Link>
        {' '}
        from
        {' '}
        {lowestPrice}
        {expanded && (
          <IconButton onClick={handleToggle}>
            <ExpandLessIcon />
          </IconButton>
        )}
      </Box>
      {expanded && <PackagesContainer advisor={advisor} />}
    </Container>
  );
};

FeedPackagesContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default FeedPackagesContainer;
