import PropTypes from 'prop-types';

const CallListingShape = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  listing_type: PropTypes.number,
  feedback_count: PropTypes.number,
  rating: PropTypes.number,
  photo_url: PropTypes.string,
  price_per_minute: PropTypes.number,
  slug: PropTypes.string,
  availability_status_text_for_hover: PropTypes.string,
  recorded: PropTypes.bool,
  inherently_away: PropTypes.bool,
  away: PropTypes.bool,
  live: PropTypes.bool,
  busy: PropTypes.bool,
  taking_call: PropTypes.bool,
  login: PropTypes.string,
  advisor_slug: PropTypes.string,
  push_channel: PropTypes.string,
  full_category: PropTypes.string,
  can_text: PropTypes.bool,
  advisor_id: PropTypes.number,
  blocked: PropTypes.bool,
  listing_category_path: PropTypes.string,
  blocked_by_viewer: PropTypes.bool,
  advisor_free_minutes: PropTypes.number,
  system_free_minutes: PropTypes.number,
});

export default CallListingShape;
