import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import ChatListingCard from 'app/components/ChatListingCard';

const ChatBrowseResults = ({ results }) => {
  return (
    <Box
      px={{ xs: 0, sm: 2 }}
      pt={{ xs: 2.5, sm: 3.5 }}
      pb={{ xs: 10, sm: 10 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: { xs: '3px', sm: '3px' },
          justifyContent: { xs: 'center', sm: 'start' },
        }}
      >
        {results.map((result) => (
          <Box
            key={`chat_listing_${result.id}`}
            sx={{
              width: { xs: '330px', sm: '330px' },
              flex: { xs: '0 0 330px', sm: '0 0 330px' },
            }}
          >
            <ChatListingCard advisorLogin={result.login} key={result.id} listing={result} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

ChatBrowseResults.propTypes = {
  results: PropTypes.array.isRequired,
};

export default ChatBrowseResults;
