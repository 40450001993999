import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import FollowButtonContainer from '../containers/FollowButtonContainer';
import IconLink from './shared/IconLink';
import Subscription from 'app/components/subscriptions/Subscription';
import useFlirtActions from 'app/hooks/useFlirtActions';
import { useGetAdvisorQuery } from 'app/api/mainApi';
import useProfileImages from 'app/hooks/useProfileImages';
import AuthenticatedLink from './shared/AuthenticatedLink';

const FlirtCard = ({ subscriptionData, follow, currentUser }) => {
  const { subscription: { advisor_login: advisorName, advisor_id: advisorId } } = subscriptionData;
  const { onTributeButtonClick } = useFlirtActions({ advisor: { id: advisorId, login: advisorName } });
  const { data: advisor } = useGetAdvisorQuery({ login: advisorName });
  const { acceptedAvatarImage: avatarImage, coverImage } = useProfileImages(advisor);
  const avatarStyles = { backgroundImage: `url('${avatarImage}')` };
  const coverStyles = { backgroundImage: `url('${coverImage}')` };

  return (
    <div className="card">
      <div className="nfp-card-hero-bg" style={coverStyles}><div className="nfp-hero-opac">&nbsp;</div></div>
      <div className="nfp-card">
        <div className="container-max margintop25">
          <div className="container-fluid container-main-info">
            <div className="row">
              <div className="col-xs-4 center-xs first-part">
                <div className="nfp-avatar-hero" style={avatarStyles}>&nbsp;</div>
              </div>
              <div className="col-xs-8 start-xs second-part">
                <Link to={`/feed/${advisorName}`}>
                  <h2>{advisorName}</h2>
                </Link>
                <ul className="nav-card-hero">
                  <li>
                    <AuthenticatedLink
                      role="link"
                      tabIndex={0}
                      currentUser={currentUser}
                      title="Pay a Tribute"
                      onClick={onTributeButtonClick}
                    >
                      <img
                        src="/plus_frontend/assets/svg/ico-top-tribute.svg"
                        alt="Pay a Tribute"
                        className="nav-hero-icon"
                      />
                    </AuthenticatedLink>
                  </li>
                  <li>
                    <IconLink
                      title="Chat"
                      href={`/chat/${advisor?.slug}`}
                      iconSrc="/plus_frontend/assets/svg/ico-top-chat.svg"
                      altText="Chat"
                      imgClasses="nav-hero-icon"
                    />
                  </li>
                  <li>
                    <IconLink
                      title="Call"
                      href={advisorName ? `/${advisorName}#live` : '#'}
                      iconSrc="/plus_frontend/assets/svg/ico-top-call.svg"
                      altText="Call"
                      imgClasses="nav-hero-icon"
                    />
                  </li>
                  <li>
                    <FollowButtonContainer
                      subscriptionData={subscriptionData}
                      followData={follow}
                      fetchStateFromApi={false}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-buttons">
        <Subscription
          subscriptionData={subscriptionData}
          fetchStateFromApi={false}
        />
      </div>
    </div>
  );
};

FlirtCard.defaultProps = {
  follow: null,
  currentUser: null,
};

FlirtCard.propTypes = {
  subscriptionData: PropTypes.object.isRequired,
  follow: PropTypes.object,
  currentUser: PropTypes.object,
};

export default FlirtCard;
