import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Button, Stack, Typography, Box } from '@mui/material';
import { FormWrapper, FormLevelErrors } from 'app/components/form_components';
import useServerErrors from 'app/hooks/form/useServerErrors';
import PageBackground from 'app/components/layout/PageBackground';
import { useGetPasswordResetQuery, useUpdatePasswordResetMutation } from 'app/api/mainApi';
import PasswordFields from 'app/components/account_info/PasswordFields';
import useRecaptcha from 'app/hooks/useRecaptcha';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PasswordResetContainer = () => {
  const navigate = useNavigate();
  const queryParams = useQuery();
  const id = queryParams.get('id');
  const token = queryParams.get('token');
  const recordKey = 'user';
  const [isSubmitted, setIsSubmitted] = useState(false);

  const query = useGetPasswordResetQuery({ id, token }, { skip: isSubmitted });
  const [update, updateStatus] = useUpdatePasswordResetMutation();

  const { executeRecaptcha, recaptchaComponent } = useRecaptcha();

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      id,
      token,
    },
  });

  useServerErrors(updateStatus, recordKey, form);

  useEffect(() => {
    const initialFieldValues = {
      id,
      token,
    };
    form.reset(initialFieldValues);
  }, [form.reset, id, token]);

  const onSubmit = (formData, captchaToken) => {
    setIsSubmitted(true);
    formData['g-recaptcha-response'] = captchaToken;
    update(formData);
  };

  const onCaptchaChange = (captchaToken) => {
    form.handleSubmit((formData) => onSubmit(formData, captchaToken))();
  }

  const onCaptchaError = (error) => {

  }

  // Redirect to /forgot_password if there's an error in the query
  useEffect(() => {
    if (query.error) {
      window.location.href = '/forgot_password';
    }
  }, [query.error]);

  useEffect(() => {
    if (updateStatus.isSuccess) {
      navigate('/new_password/confirm');
    }
  }, [updateStatus, navigate]);

  return (
    <PageBackground backgroundColor="white">
      <Box px={{ xs: 3, sm: 4 }} style={{ maxWidth: '500px', margin: '100px auto' }}>
        <FormWrapper form={form}>
          <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
            <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
            <Typography variant="h3">
              New Password
            </Typography>
            <Typography variant="body1">
              Thank you! Please enter a new password below. This will be your new NiteFlirt password, so choose something that is easy for you to remember.
            </Typography>
          </Stack>
          <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
            <PasswordFields />
          </Stack>
          <FormLevelErrors />
          <Stack direction="row" justifyContent="center" mb={3}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={executeRecaptcha}
              disabled={updateStatus.isLoading || updateStatus.isSuccess || !form.formState.isValid}
            >
              Reset Password
            </Button>
          </Stack>
        </FormWrapper>
      </Box>
      { recaptchaComponent(onCaptchaChange, onCaptchaError)}
    </PageBackground>
  );
};

export default PasswordResetContainer;
