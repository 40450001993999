import React from 'react';
import { PropTypes } from 'prop-types';

import useFlirtActions from 'app/hooks/useFlirtActions';
import AuthenticatedLink from '../components/shared/AuthenticatedLink';
import { useGetAppConfigQuery } from 'app/api/mainApi';

const TributeButtonContainer = ({ post }) => {
  // faking up an "advisor" object from post account data
  const advisor = { id: post?.account.main_user_id, login: post?.account.login };
  const { onTributeButtonClick } = useFlirtActions({ advisor });
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;

  return (
    <AuthenticatedLink
      data-test-id="tribute-button"
      role="link"
      tabIndex={0}
      currentUser={currentUser}
      onClick={onTributeButtonClick}
    >
      <div className="row middle-xs">
        <div><img src="/plus_frontend/assets/svg/ico-tribute.svg" title="Pay a Tribute" alt="Pay a Tribute" /></div>
      </div>
    </AuthenticatedLink>
  );
};

TributeButtonContainer.defaultProps = {
  post: null,
};

TributeButtonContainer.propTypes = {
  post: PropTypes.object,
};

export default TributeButtonContainer;
