import { useGetAppConfigQuery } from 'app/api/mainApi';

// This is a versatile function that can be used to wrap any function that requires a current user.
// Optional arguments:
// - currentUser: if you don't pass it in, we will do a lookup;
//   but if this function is called from a hook, you'll need to pass in currentUser
//   to avoid a "hooks in a hook" error
// - returnUrl: if you want to redirect to a different page than it says in the address bar
//
// Required arguments:
// - action: the function you want to run if the user is authenticated
//
// Returns a function that can handle the original action you wanted to take.  That function
//  will return ANOTHER function, which can be used in a click handler, in case you need to
//  swallow the click event with preventDefault.
//  Example:
//   For the click handler version, see AuthenticatedLink below.
//   For the programmatic version, something like this:
//       const action = () => openTributeModal({ advisor });
//       authenticatedFunction({
//         currentUser,
//         returnUrl: `/${advisor?.login}/goodies/${goodyBag?.id}/buy`,
//         action,
//       })();
//   Note the 2 pairs of chained parentheses!
export const authenticatedFunction = ({ returnUrl, action, currentUser = null }) => {
  return (e) => {
    if (!currentUser) {
      e?.preventDefault();
      const path = returnUrl || window.location.pathname;
      const search = path.includes('?') ? '' : window.location.search;
      const hash = path.includes('#') ? `#${path.split('#')[1]}` : window.location.hash;
      const pathWithoutHash = path.replace(hash, '');
      const hasQuestionMark = path.includes('?') || (search.length > 0);

      const encodedReturnUrl = encodeURIComponent(
        `${pathWithoutHash}${search}${hasQuestionMark ? '&' : '?'}fromLogin=true${hash}`,
      );
      window.location.href = `/login?return_url=${encodedReturnUrl}`;
      return false;
    }

    return action(e);
  };
};

export const userHasCard = (user) => {
  return (Object.keys(user?.default_card || {}).length > 0);
};

export const contextAwareChatUrl = (currentUser, advisor) => {
  if (currentUser?.is_advisor) {
    return '/chat';
  } else {
    return `/chat/${advisor?.slug}?start_chat=true`;
  }
};

export const feedsUrlForUser = (user) => {
  return `/feed/${user?.login}`;
};
