import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import feedsApi from 'app/api/feedsApi';
import mainApi from 'app/api/mainApi';
import assetApi from 'app/api/assetApi';
import profileImageApi from 'app/api/profileImageApi';
import flashSlice from 'app/slices/flashSlice';
import snackbarMessageSlice from 'app/slices/snackbarMessageSlice';
import { flashMiddleware, snackbarMiddleware } from 'app/middleware/uiPopupMessage';
import { clientErrorsMiddleware } from 'app/middleware/clientErrors';
import { AvailabilityRefresher } from 'app/middleware/availabilityRefresher';
import resetRecordsSlice from 'app/slices/resetRecordsSlice';
import resetCommentsSlice from 'app/slices/resetCommentsSlice';
import subscriptionSlice from 'app/slices/subscriptionSlice';
import tributesSlice from 'app/slices/tributesSlice';
import chatSlice from 'app/slices/chatSlice';
import packageSlice from 'app/slices/packageSlice';
import goodyBagFilterSlice from 'app/slices/goodyBagFilterSlice';
import chatProfileFilterSlice from 'app/slices/chatProfileFilterSlice';
import miscUIStateSlice from 'app/slices/miscUIStateSlice';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  [feedsApi.reducerPath]: feedsApi.reducer,
  [mainApi.reducerPath]: mainApi.reducer,
  [assetApi.reducerPath]: assetApi.reducer,
  [profileImageApi.reducerPath]: profileImageApi.reducer,
  flashes: flashSlice.reducer,
  snackbarMessages: snackbarMessageSlice.reducer,
  resetRecords: resetRecordsSlice.reducer,
  resetComments: resetCommentsSlice.reducer,
  subscription: subscriptionSlice.reducer,
  tributes: tributesSlice.reducer,
  chat: chatSlice.reducer,
  packageOnboarding: packageSlice.reducer,
  miscUIState: miscUIStateSlice.reducer,
  goodyBagFilter: goodyBagFilterSlice.reducer,
  chatProfileFilter: chatProfileFilterSlice.reducer,
});

// keys from rootReducer that we want to persist into local storage
const whitelistForPersistence = [
  // mainApi.reducerPath, // example of persisting an entire API
  // resetRecords, // example of persisting a slice
  'miscUIState',
  'goodyBagFilter',
  'chatProfileFilter',
];

const persistConfig = {
  key: 'root',
  storage,
  // see rootReducer above- only the reducer keys mentioned here will be persisted in local storage
  whitelist: whitelistForPersistence,
};

export const store = configureStore({
  // to use redux-persist, we need to wrap our reducer in persistReducer.
  reducer: persistReducer(persistConfig, rootReducer),
  devTools: true,
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      feedsApi.middleware,
      mainApi.middleware,
      assetApi.middleware,
      profileImageApi.middleware,
      flashMiddleware,
      snackbarMiddleware,
      clientErrorsMiddleware,
      AvailabilityRefresher,
    ])
  ),
});

export const persistor = persistStore(store);
export const { purge } = persistor; // function for clearing the persisted state

// // Commented for now, since currently everything we store in localStorage is non-sensitive
// We automatically purge the data store if A.) the data store is too old, or B.) the server has been restarted recently
// const DATA_STALE_AFTER = 1 * 60 * 60; // 1 hour
// const purgeIfNecessary = () => {
//   const currentTimestamp = Math.floor(Date.now() / 1000);
//   const reduxInitializedAtString = window.localStorage.getItem('oldestReduxData');
//   const reduxInitializedAt = reduxInitializedAtString ? parseInt(reduxInitializedAtString, 10) : 0;
//   const ageOfDataStore = currentTimestamp - reduxInitializedAt;
//   const dataStoreTooOld = ageOfDataStore > DATA_STALE_AFTER;
//   const restartedRecently = reduxInitializedAt < window.lastRestartAt;

//   if (dataStoreTooOld || restartedRecently) {
//     persistor.purge();
//     window.localStorage.setItem('oldestReduxData', currentTimestamp);
//   }
// };
// purgeIfNecessary();
