import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import { useGetAppConfigQuery, useGetAvailableBalanceQuery } from 'app/api/mainApi';
import { useGetGoodyBagQuery } from 'app/api/assetApi';
import { toNumber } from 'app/helpers/currencyHelpers';

import ModalsContext from 'app/contexts/ModalsContext';
import useGoodyBagActions from 'app/hooks/useGoodyBagActions';
import BuyGoodyBagModal from 'app/components/modals/BuyGoodyBagModal';

const BuyGoodyBagModalContainer = ({ goodyBagId, advisorLogin }) => {
  const { openModal, closeModal, closeSelfAndOpenModal } = useContext(ModalsContext);

  const { data: goodyBag, isLoading: goodyBagLoading } = useGetGoodyBagQuery(goodyBagId);
  const { data: balanceData, isLoading: balanceLoading } = useGetAvailableBalanceQuery();
  const { data: appConfig, isLoading: appConfigLoading } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const viewingSelf = false;
  const rawAvailableBalance = balanceData?.available_balance;
  const availableBalance = toNumber(rawAvailableBalance);
  const advisor = { login: advisorLogin };

  // in the end, this is just used as a generic "not ready yet" flag
  const advisorLoading = goodyBagLoading || balanceLoading || appConfigLoading;

  const { openBuyGoodyBagModal } = useGoodyBagActions({
    goodyBag,
    viewingSelf,
    availableBalance,
    currentUser,
    advisorLoading,
    advisor,
  });

  if (!goodyBag) return null;

  return (
    <BuyGoodyBagModal
      goodyBag={goodyBag}
      advisor={advisor}
      currentUser={currentUser}
      openModal={openModal}
      closeModal={closeModal}
      closeSelfAndOpenModal={closeSelfAndOpenModal}
      availableBalance={availableBalance}
      onAddToBalanceSuccess={openBuyGoodyBagModal}
    />
  );
};

BuyGoodyBagModalContainer.propTypes = {
  goodyBagId: PropTypes.number.isRequired,
  advisorLogin: PropTypes.string.isRequired,
};

export default BuyGoodyBagModalContainer;
