import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';

import CallListingShape from 'app/shapes/CallListingShape';

const ViewerBlockedError = ({
  listing,
  isLoading,
}) => {
  if (isLoading || !listing.blocked) {
    return null;
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      sx={{ textAlign: 'center' }}
      mt={3}
    >
      <Typography variant="error">
        We are unable to place this call because this Flirt has blocked you.
      </Typography>
      <Box mt={1}>
        <a href={`${listing.listing_category_path}`}>
          <Button
            variant="contained"
            size="small"
          >
            Find a Flirt
          </Button>
        </a>
      </Box>
    </Stack>
  );
};

ViewerBlockedError.defaultProps = {
  listing: null,
};

ViewerBlockedError.propTypes = {
  listing: CallListingShape,
  isLoading: PropTypes.bool.isRequired,
};

export default ViewerBlockedError;
