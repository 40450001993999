import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import CreatePackage from 'app/components/packages/CreatePackage';

import {
  useCreatePackageMutation,
  useGetMyPackagesQuery,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';
import { useGetCreatorFeedContentAvailableQuery } from 'app/api/feedsApi';

import {
  setPackageRate,
  setPackageFeedAccess,
} from 'app/slices/packageSlice';

import useInvalidateCacheTagsDispatcher from 'app/hooks/useInvalidateCacheTagsDispatcher';
import { APP_CONFIG_TAG } from 'app/api/sharedApiTags';

import {
  toNumber,
  amountOutOfRange,
} from 'app/helpers/currencyHelpers';

const CreatePackageContainer = () => {
  const [privatePackage, setPrivatePackage] = useState(false);
  const [atMaxPublicPackages, setAtMaxPublicPackages] = useState(false);
  const [rateError, setRateError] = useState('');
  const [freeMinutes, setFreeMinutes] = useState(false);
  const [freeVolleys, setFreeVolleys] = useState(false);
  const [callsPerSessionAmount, setCallsPerSessionAmount] = useState(3);
  const [callsLimitByPeriod, setCallsLimitByPeriod] = useState(true);
  const [callsPerPeriodAmount, setCallsPerPeriodAmount] = useState(10);
  const [callsPerPeriodAmountError, setCallsPerPeriodAmountError] = useState(false);
  const [volleysPerPeriodAmount, setVolleysPerPeriodAmount] = useState(20);
  const [volleysPerPeriodAmountError, setVolleysPerPeriodAmountError] = useState(false);
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const { data: packages } = useGetMyPackagesQuery(currentUser);
  const rate = useSelector(state => state.packageOnboarding.rate);
  const feedAccess = useSelector(state => state.packageOnboarding.feedAccess);
  const { dispatchInvalidateCacheTagsEvent } = useInvalidateCacheTagsDispatcher();

  const isOnboarding = new URLSearchParams(window.location.search)
    .get('onboarding') === 'true';

  const contentAvailableResult = useGetCreatorFeedContentAvailableQuery(
    { advisorId: currentUser?.id },
    { skip: !currentUser },
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [
    createPackage, {
      isSuccess: createPackageSuccess,
      error: createPackageError,
      isLoading: createPackageIsLoading,
    },
  ] = useCreatePackageMutation();

  useEffect(() => {
    const err = createPackageError;

    if (err) {
      const errorMessages = err.data.errors.join(', ');
      dispatch(showSnackbarMessage({
        message: errorMessages,
        severity: 'error',
      }));

      switch (err.data.record) {
      case 'RecurringTributes::Package':
        setRateError(errorMessages);
        break;
      case 'RecurringTributes::CallPerk':
        setCallsPerPeriodAmountError(true);
        break;
      case 'RecurringTributes::VolleyPerk':
        setVolleysPerPeriodAmountError(true);
        break;
      default:
        console.warn('Unhandled error', createPackageError.data.record);
      }
    }
  }, [createPackageError]);

  useEffect(() => {
    if (createPackageSuccess) {
      dispatch(showSnackbarMessage({
        message: 'Your package has been created successfully!',
        severity: 'success',
      }));

      if (isOnboarding) {
        // need to invalidate the app config cache to get
        // new onboarding step for current user
        dispatchInvalidateCacheTagsEvent([APP_CONFIG_TAG]);
        navigate(`/feed/${currentUser.login}/onboarding`);
      } else {
        navigate('/feed/packages');
      }
    }
  }, [createPackageSuccess]);

  useEffect(() => {
    if (packages && packages.publicAndPendingOnboardingPackages.length >= 4) {
      setPrivatePackage(true);
      setAtMaxPublicPackages(true);
    }
  }, [packages]);

  useEffect(() => {
    if (isOnboarding) {
      dispatch(setPackageRate({ rate }));
      dispatch(setPackageFeedAccess({ feedAccess: true }));
      setPrivatePackage(false);
    } else {
      dispatch(setPackageFeedAccess({ feedAccess: false }));
    }
  }, []);

  useEffect(() => {
    if (callsPerPeriodAmount < 1 || callsPerPeriodAmount > 30) {
      setCallsPerPeriodAmountError(true);
    } else {
      setCallsPerPeriodAmountError(false);
    }
  }, [callsPerPeriodAmount]);

  useEffect(() => {
    if (volleysPerPeriodAmount < 1 || volleysPerPeriodAmount > 60) {
      setVolleysPerPeriodAmountError(true);
    } else {
      setVolleysPerPeriodAmountError(false);
    }
  }, [volleysPerPeriodAmount]);

  const onRateChange = (rateString) => {
    if (amountOutOfRange(toNumber(rateString), 3.99, 999)) {
      setRateError('Package rate should be from $3.99 to $999');
    }

    dispatch(setPackageRate({ rate: rateString }));
  };

  const onFeedAccessChange = (feedAccess) => {
    dispatch(setPackageFeedAccess({ feedAccess }));
  };

  const doCreatePackage = () => {
    return createPackage({
      rate,
      status: privatePackage ? 'private_package' : 'public_package',
      feedAccess,
      freeMinutes,
      freeVolleys,
      callsPerSessionAmount,
      callsLimitByPeriod,
      callsPerPeriodAmount,
      volleysPerPeriodAmount,
    });
  };

  const onSaveClick = doCreatePackage;

  const onCancelClick = () => {
    if (isOnboarding) {
      doCreatePackage().then(() => {
        // onboarding container looks for no_perks query param via URLSearchParams,
        // navigate() doesn't seem to work with URLSearchParams, so we're
        // resetting window.location.href instead
        window.location.href = `/feed/${currentUser.login}/onboarding?no_perks=true`;
      });
    } else {
      navigate('/feed/packages');
    }
  };

  return (
    <AppBodyWithSidebar>
      <CreatePackage
        isOnboarding={isOnboarding}
        //
        privatePackage={privatePackage}
        setPrivatePackage={setPrivatePackage}
        //
        atMaxPublicPackages={atMaxPublicPackages}
        //
        rate={rate?.toString()}
        onRateChange={onRateChange}
        rateError={rateError}
        setRateError={setRateError}
        //
        feedAccess={feedAccess}
        onFeedAccessChange={onFeedAccessChange}
        showFeedPerkToggle={contentAvailableResult.data?.content_available || false}
        //
        freeMinutes={freeMinutes}
        setFreeMinutes={setFreeMinutes}
        //
        freeVolleys={freeVolleys}
        setFreeVolleys={setFreeVolleys}
        //
        callsPerSessionAmount={callsPerSessionAmount}
        setCallsPerSessionAmount={setCallsPerSessionAmount}
        //
        callsLimitByPeriod={callsLimitByPeriod}
        setCallsLimitByPeriod={setCallsLimitByPeriod}
        //
        callsPerPeriodAmount={callsPerPeriodAmount.toString()}
        setCallsPerPeriodAmount={setCallsPerPeriodAmount}
        callsPerPeriodAmountError={callsPerPeriodAmountError}
        //
        volleysPerPeriodAmount={volleysPerPeriodAmount.toString()}
        setVolleysPerPeriodAmount={setVolleysPerPeriodAmount}
        volleysPerPeriodAmountError={volleysPerPeriodAmountError}
        //
        onCancelClick={onCancelClick}
        onSaveClick={onSaveClick}
        createPackageIsLoading={createPackageIsLoading}
        //
        hasAtLeastOnePerk={freeMinutes || freeVolleys || feedAccess}
      />
    </AppBodyWithSidebar>
  );
};

export default CreatePackageContainer;
