import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FeedbackShape from 'app/shapes/FeedbackShape';

import { Button } from '@mui/material';

import ModalsContext from 'app/contexts/ModalsContext';
import LeaveFeedbackModalContainer from 'app/containers/modals/LeaveFeedbackModalContainer';

const LeaveFeedbackButton = ({ feedback, size, variant }) => {
  const { openModal } = useContext(ModalsContext);

  const openLeaveFeedbackModalContainer = () => {
    openModal({
      component: LeaveFeedbackModalContainer,
      props: {
        objectType: feedback.object_type,
        objectId: feedback.object_id,
      },
    });
  };

  return (
    <Button
      data-test-id="leave-feedback-button"
      variant={variant}
      size={size}
      onClick={openLeaveFeedbackModalContainer}
    >
      Leave Feedback
    </Button>
  );
};

LeaveFeedbackButton.defaultProps = {
  size: 'small',
  variant: 'contained',
};

LeaveFeedbackButton.propTypes = {
  feedback: FeedbackShape.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
};

export default LeaveFeedbackButton;
