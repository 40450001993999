import React from 'react';
import { PropTypes } from 'prop-types';

const VideoMediaPreview = ({ url, onImageClick }) => {
  return (
    <a role="link" tabIndex={0} onClick={onImageClick}>
      <div className="slick-item-bg">
        <div className="slick-black">
          <div className="video-container">
            <img alt="preview" src={url} />
          </div>
        </div>
      </div>
    </a>
  );
};

VideoMediaPreview.propTypes = {
  url: PropTypes.string.isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default VideoMediaPreview;
