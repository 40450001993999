import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import {
  useGetCallListingQuery,
  useGetPhonesQuery,
  useGetAvailableBalanceQuery,
  useGetAppConfigQuery,
  useGetCallSystemStatusQuery,
  useLazyGetCallStatusQuery,
  useCreateCallMutation,
} from 'app/api/mainApi';

import {
  Box,
  Divider,
  Grid,
} from '@mui/material';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';

import AppBody from 'app/components/layout/AppBody';
import DeprecatedPhoneListingCard from 'app/components/DeprecatedPhoneListingCard';
import CallInfo from 'app/components/call/CallInfo';
import SelectPhoneNumber from 'app/components/call/SelectPhoneNumber';
import AddPhoneLink from 'app/components/call/AddPhoneLink';
import ListingOffers from 'app/components/call/ListingOffers';
import CurrentBalance from 'app/components/call/CurrentBalance';
import TalkTime from 'app/components/call/TalkTime';
import CallNow from 'app/components/call/CallNow';
import HowItWorksToggle from 'app/components/call/HowItWorksToggle';

import CallInProgress from 'app/components/call/CallInProgress';

import BlockedByViewerError from 'app/components/call/errors/BlockedByViewerError';
import CannotCallSelfError from 'app/components/call/errors/CannotCallSelfError';
import ViewerBlockedError from 'app/components/call/errors/ViewerBlockedError';
import CallSystemDownError from 'app/components/call/errors/CallSystemDownError';
import NeedsPhoneError from 'app/components/call/errors/NeedsPhoneError';

import SimulatedCallStates from 'app/components/call/SimulatedCallStates';

import { toNumber } from 'app/helpers/currencyHelpers';

const DEFAULT_IVR_ENTRY_NODE = 'recording';
const OUTBOUND_CALL_SIGNAL = 'outbound';

// TODO: Do we need to use local storage or server storage to store ngtokens in case the user is on a call and refreshes the page.
// I believe the classic UI just says you are already busy on a call if you end up refreshing the page.

const CallContainer = ({ setShowHowItWorks }) => {
  const { data: callSystemStatus, isLoading: isCallSystemStatusLoading } = useGetCallSystemStatusQuery();
  const { data: appConfig, isLoading: isAppConfigLoading } = useGetAppConfigQuery();
  const { current_user: currentUser } = appConfig || {};
  const { id: listingId, data: urlData } = useParams();
  const { data: listing, isLoading: isListingLoading } = useGetCallListingQuery({ id: listingId });
  const { data: phones, isLoading: isPhonesLoading } = useGetPhonesQuery();
  const [selectedPhone, setSelectedPhone] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);
  const { data: balance, isLoading: isBalanceLoading } = useGetAvailableBalanceQuery();
  const isListingOwner = listing?.advisor_id === currentUser?.id;
  const isViewerBlocked = listing?.blocked;
  const isSimulatorEnabled = !!appConfig.simulateCallStates;

  const [simulatedCallType, setSimulatedCallType] = useState('live');
  const [simulatedCallStatus, setSimulatedCallStatus] = useState('created');

  const [createCall, {
    isLoading: isCreateCallLoading,
    isSuccess: isCreateCallSuccess,
    data: callData,
    isError: isCallError,
    error: callError,
  }] = useCreateCallMutation();
  const [callStatus, setCallState] = useState({
    conference_id: null,
    bucket_transaction_id: null,
    status: null,
    message: null,
    ngtoken: null,
  });

  // NOTE: The classic pages had the ability of supporting a URL data attribute to set the IVR entry node.
  const IvrEntryNode = urlData || DEFAULT_IVR_ENTRY_NODE;
  const createCallParams = {
    listingId,
    signal: OUTBOUND_CALL_SIGNAL,
    data: IvrEntryNode,
    selectedPhone: selectedPhone?.id,
  };
  const params = isSimulatorEnabled ? {
    ...createCallParams,
    callType: simulatedCallType,
    fakeStatus: simulatedCallStatus,
  } : createCallParams;
  const triggerCall = () => {
    setShowHowItWorks(false);
    createCall(params);
  };

  const [pollingInterval, setPollingInterval] = useState(8000);

  const [getCallStatusQuery, {
    isSuccess: isCurrentCallStatusSuccess,
    data: currentCallStatus,
    isError: isCurrentCallStatusError,
    error: callStatusError,
  }] = useLazyGetCallStatusQuery();
  const triggerGetCallStatus = () => {
    const timestamp = new Date().getTime();
    const baseParams = {
      listingId,
      ngtoken: callStatus.ngtoken,
      timestamp,
    };
    const params = isSimulatorEnabled ? {
      ...baseParams,
      callType: simulatedCallType,
      fakeStatus: simulatedCallStatus,
    } : baseParams;

    getCallStatusQuery(params);
  };

  useEffect(() => {
    if (phones) {
      const defaultPhone = phones.find((phone) => phone.default);
      setSelectedPhone(defaultPhone || phones[0]);
    }
  }, [phones]);

  useEffect(() => {
    if (isCreateCallSuccess) {
      setCallInProgress(true);
      setCallState(callData);
    }
  }, [isCreateCallSuccess, callData]);

  useEffect(() => {
    if (isCallError) {
      setShowHowItWorks(true);
      setCallInProgress(false);
    }
  }, [isCallError, callError]);

  useEffect(() => {
    if (isCurrentCallStatusSuccess) {
      if (currentCallStatus.status !== 'noop') { setCallState(currentCallStatus); }
    } else if (isCurrentCallStatusError) {
      // We 404 if there is no longer a call, so we should do nothing and continue to show the billing_ended step.
      if (callStatusError.status !== 404) {
        setShowHowItWorks(true);
        setCallInProgress(false);
        console.error(callStatusError);
        // Set some sort of error message
      }
    }
  }, [currentCallStatus]);

  // TODO: They cannot call if they don't have the balance to cover the cost of the entire recording
  // Darren to file bug tickets or feature stories around the behavior of the call button and recordings.
  // Recordings can only be called if the account balance can cover the length of the entire recording. Nothing in the controller
  // says otherwise though.
  const isLoading = isBalanceLoading || isListingLoading || isPhonesLoading;
  const canCall = !isLoading && selectedPhone && (toNumber(balance.available_balance) >= listing.price_per_minute || listing.system_free_minutes > 0 || toNumber(listing.advisor_free_minutes) > 0);

  const content = callInProgress ? (
    <CallInProgress
      triggerGetCallStatus={triggerGetCallStatus}
      callStatus={callStatus}
      isCreateCallLoading={isCreateCallLoading}
      pollingInterval={pollingInterval}
      selectedPhone={selectedPhone}
      listing={listing}
      balance={balance}
      isLoading={isLoading}
      triggerCall={triggerCall}
      currentUser={currentUser}
    />
  ) : (
    <>
      <CallInfo />
      { phones && phones.length > 0 ? (
        <SelectPhoneNumber
          phones={phones}
          setSelectedPhone={setSelectedPhone}
          isLoading={isPhonesLoading}
        />
      ) : (
        <AddPhoneLink
          currentUser={currentUser}
          phones={phones}
        />
      ) }
      <ListingOffers listing={listing} />
      <CurrentBalance
        balance={balance}
        listing={listing}
        isBalanceLoading={isBalanceLoading}
        isListingLoading={isListingLoading}
        currentUser={currentUser}
      />
      <TalkTime
        listing={listing}
        balance={balance}
        isLoading={isBalanceLoading || isListingLoading}
        canCall={canCall}
      />
      <HowItWorksToggle />
      <ViewerBlockedError
        listing={listing}
        isLoading={isListingLoading}
      />
      <CannotCallSelfError
        listing={listing}
        viewer={currentUser}
        isLoading={isAppConfigLoading || isListingLoading}
      />
      { !isCallSystemStatusLoading && !callSystemStatus.up && (
        <CallSystemDownError />
      )}
      { listing?.blocked_by_viewer && (
        <BlockedByViewerError listing={listing} />
      )}
      { !(isViewerBlocked || isListingOwner) && (isCallSystemStatusLoading || callSystemStatus.up) && !listing?.blocked_by_viewer && (
        <>
          <CallNow
            selectedPhone={selectedPhone}
            listing={listing}
            balance={balance}
            canCall={canCall}
            triggerCall={triggerCall}
          />
          <NeedsPhoneError
            phones={phones}
            isLoading={isPhonesLoading}
          />
        </>
      )}
    </>
  );

  return (
    <AppBody>
      <Grid container alignItems="center" className="container-hdr-sticky2">
        <Grid item p={1}>
          <a
            role="link"
            tabIndex="0"
            onClick={() => window.history.back()}
          >
            <ArrowBackIosSharpIcon
              htmlColor="white"
            />
          </a>
        </Grid>
        <Grid item p={1}>
          Call
        </Grid>
      </Grid>
      <Box sx={{
        marginTop: '8px',
        paddingTop: '40px',
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        mb: 1,
      }}
      >
        { !isListingLoading && (
          <DeprecatedPhoneListingCard
            listing={listing}
            hideCallButton
          />
        )}
      </Box>
      <Divider
        sx={{
          mt: 3,
          mx: '32px',
        }}
      />
      { isSimulatorEnabled && (
        <SimulatedCallStates
          callInProgress={callInProgress}
          simulatedCallType={simulatedCallType}
          setSimulatedCallType={setSimulatedCallType}
          simulatedCallStatus={simulatedCallStatus}
          setSimulatedCallStatus={setSimulatedCallStatus}
          pollingInterval={pollingInterval}
          setPollingInterval={setPollingInterval}
        />
      )}
      { content }
    </AppBody>
  );
};

CallContainer.propTypes = {
  setShowHowItWorks: PropTypes.func.isRequired,
};

export default CallContainer;
