import { showFlash } from 'app/slices/flashSlice';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import ModalsContext from 'app/contexts/ModalsContext';
import BuyGoodyBagModal from 'app/components/modals/BuyGoodyBagModal';
import { authenticatedFunction } from 'app/helpers/userHelper';

const useGoodyBagActions = ({
  goodyBag,
  viewingSelf,
  availableBalance,
  currentUser,
  advisorLoading,
  advisor,
  returnUrlTemplate,
}) => {
  const dispatch = useDispatch();
  const { openModal, closeModal, closeSelfAndOpenModal } = useContext(ModalsContext);

  const openBuyGoodyBagModal = (onAddToBalanceSuccess) => {
    closeModal();
    openModal({
      component: BuyGoodyBagModal,
      props: {
        goodyBag,
        advisor,
        currentUser,
        openModal,
        closeModal,
        closeSelfAndOpenModal,
        availableBalance,
        onAddToBalanceSuccess,
      },
    });
  };

  const returnUrl = returnUrlTemplate?.replace(':goodyBagId', goodyBag.id);

  const onGoodyBagButtonClick = () => {
    if (advisorLoading) return;
    if (viewingSelf) {
      dispatch(
        showFlash({
          flashType: 'error',
          props: { message: 'We\'re sorry, you cannot purchase your own goody bag.' },
        }),
      );
    } else {
      const action = () => openBuyGoodyBagModal(openBuyGoodyBagModal);
      // send them to login page with a returnUrl other than what is in address bar; so they can resume the
      // action that was interrupted by the login process.
      authenticatedFunction({
        currentUser,
        action,
        returnUrl,
      })();
    }
  };

  return {
    onGoodyBagButtonClick,
    openBuyGoodyBagModal,
    returnUrl,
  };
};

export default useGoodyBagActions;
