import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Select,
  MenuItem,
} from '@mui/material';

// Taken from Conference::RECORDED_STATUS_UPDATES
const RecordedListingStatuses = [
  'error',
  'created',
  'seeker_answered',
  'billing_started',
  'billing_ended',
  // 'declined_by_seeker',
  // 'unauthorized',
  // 'unreachable',
  // 'busy',
  // 'timeout',
  // 'badnumber',
  // 'busy_by_advisor',
  // 'busy_by_seeker',
  // 'rejected',
  // 'timeout_by_advisor',
  // 'timeout_by_seeker',
  // 'unknown',
  // 'unknown_by_advisor',
  // 'unknown_by_seeker',
  // 'exception',
  // 'unreachable_by_seeker',
  // 'unreachable_by_advisor',
  // 'declined',
  // 'declined_by_advisor',
  // 'failed_abandoned',
  // 'sit',
  // 'faxtone',
];

// Taken from Conference::simulate_get_live_listing_status
const LiveListingStatuses = [
  'error',
  'created',
  // 'add_money',
  'less_than_one_minute',
  'seeker_answered',
  'advisor_answered',
  'billing_started',
  'billing_ended',
];

const CallTypes = [
  'live',
  'recorded',
];

const SimulatedCallStates = ({
  callInProgress,
  simulatedCallType,
  setSimulatedCallType,
  simulatedCallStatus,
  setSimulatedCallStatus,
  setPollingInterval,
  pollingInterval,
}) => {
  const [hidden, setHidden] = useState(false);
  const onCallTypeChange = (event) => {
    setSimulatedCallType(event.target.value);
    setSimulatedCallStatus('created');
  };

  const onCallStatusChange = (event) => {
    setSimulatedCallStatus(event.target.value);
  };

  return (
    <Box
      textAlign="center"
    >
      <Button
        variant="contained"
        onClick={() => setHidden(!hidden)}
        sx={{ position: 'fixed', top: 0, right: 0 }}
      >
        Toggle Simulator UI
      </Button>
      <Box
        display={hidden ? 'none' : 'initial'}
      >
        Polling Interval (ms):
        <input
          type="number"
          onChange={(e) => setPollingInterval(parseInt(e.target.value))}
          value={pollingInterval}
        />
        <br />
        Call Type:
        <Select
          disabled={callInProgress}
          value={simulatedCallType}
          onChange={onCallTypeChange}
        >
          {CallTypes.map((callType) => (
            <MenuItem key={callType} value={callType}>{callType}</MenuItem>
          ))}
        </Select>
        <br />
        Call Status:
        <Select
          value={simulatedCallStatus}
          onChange={onCallStatusChange}
        >
          { simulatedCallType === 'live' && LiveListingStatuses.map((status) => (
            <MenuItem key={`live-${status}`} value={status}>{status}</MenuItem>
          ))}
          { simulatedCallType === 'recorded' && RecordedListingStatuses.map((status) => (
            <MenuItem key={`recorded-${status}`} value={status}>{status}</MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

SimulatedCallStates.propTypes = {
  callInProgress: PropTypes.bool.isRequired,
  simulatedCallType: PropTypes.string.isRequired,
  setSimulatedCallType: PropTypes.func.isRequired,
  simulatedCallStatus: PropTypes.string.isRequired,
  setSimulatedCallStatus: PropTypes.func.isRequired,
  setPollingInterval: PropTypes.func.isRequired,
  pollingInterval: PropTypes.number.isRequired,
};

export default SimulatedCallStates;
