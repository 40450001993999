import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import camelizeKeys from 'app/util/camelizeKeys';

const ALBUM_TAG = 'album';
const ITEM_TAG = 'item';
const GOODY_BAG_TAG = 'goodyBag';
const GOODY_BAG_ITEMS_TAG = 'goodyBagItems';
const GOODY_BAG_REVIEWS_TAG = 'goodyBagReviews';

const assetApi = createApi({
  reducerPath: 'assets',
  baseQuery: fetchBaseQuery({ baseUrl: '/fm/' }),
  endpoints: (builder) => ({
    getAlbums: builder.query({
      providesTags: [ALBUM_TAG],
      query: () => ('seller_albums.json'),
    }),

    getGoodyBag: builder.query({
      providesTags: (_result, _error, args) => (
        [{ type: GOODY_BAG_TAG, id: args.goodyBagId }]
      ),
      query: (goodyBagId) => `client_api/goody_bags/${goodyBagId}`,
    }),

    getItems: builder.query({
      providesTags: [ITEM_TAG],
      keepUnusedDataFor: 0,
      query: ({ albumId, cursor, imagesOnly }) => {
        let url = `client_api/items?album_id=${albumId}&cursor=${cursor}`;
        if (imagesOnly) {
          url += '&images_only=true';
        }
        return url;
      },
    }),

    getGoodyBagsForAdvisor: builder.query({
      providesTags: [GOODY_BAG_TAG],
      keepUnusedDataFor: 100,
      query: ({ userId, selectedGoodyBag = null, page = 1, perPage = 20 }) => {
        const baseUrl = `client_api/goody_bags?user_id=${userId}&page=${page}&per_page=${perPage}`;

        if (selectedGoodyBag) {
          return `${baseUrl}&selected_goody_bag=${selectedGoodyBag}`;
        }

        return baseUrl;
      },
      // transformResponse: (response) => camelizeKeys(response),
    }),

    getGoodyBagReviews: builder.query({
      providesTags: (result, error, args) => {
        return [{ type: GOODY_BAG_REVIEWS_TAG, id: `goody-bag-${args.goodyBagId}` }];
      },
      keepUnusedDataFor: 100,
      query: ({ goodyBagId }) => (`client_api/goody_bags/${goodyBagId}/goody_bag_reviews`),
    }),

    getGoodyBagItems: builder.query({
      providesTags: (result, error, args) => {
        return [{ type: GOODY_BAG_ITEMS_TAG, id: `goody-bag-${args.goodyBagId}` }];
      },
      keepUnusedDataFor: 100,
      query: ({ goodyBagId }) => (`client_api/goody_bags/${goodyBagId}/goody_bag_items`),
    }),

    goodyBagSearch: builder.mutation({
      query: (params) => {
        // turn arrays into comma-separated strings
        const transformed = Object.fromEntries(
          Object.entries(params).map(([key, value]) => [
            key,
            Array.isArray(value) ? value.join(',') : value,
          ]),
        );

        // remove empty params, or text with less than 3 characters
        const filtered = Object.fromEntries(
          Object.entries(transformed).filter(
            ([key, value]) => (
              (value !== '') && (key !== 'text' || value.length >= 3)
            ),
          ),
        );

        const queryString = new URLSearchParams(filtered).toString();

        return {
          url: `client_api/goody_search?${queryString}`,
          method: 'GET',
        };
      },
    }),

    purchaseGoodyBag: builder.mutation({
      query: ({ goodyBagId }) => ({
        url: `client_api/goody_bags/${goodyBagId}/purchase`,
        method: 'POST',
      }),
    }),

    pinGoodyBag: builder.mutation({
      query: ({ goodyBagId }) => ({
        url: `client_api/goody_bags/${goodyBagId}/goody_bag_pin`,
        method: 'POST',
      }),
      invalidatesTags: [GOODY_BAG_TAG],
    }),

    unpinGoodyBag: builder.mutation({
      query: ({ goodyBagId }) => ({
        url: `client_api/goody_bags/${goodyBagId}/goody_bag_pin`,
        method: 'DELETE',
      }),
      invalidatesTags: [GOODY_BAG_TAG],
    }),

    deleteGoodyBag: builder.mutation({
      query: ({ goodyBagId }) => ({
        url: `client_api/goody_bags/${goodyBagId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [GOODY_BAG_TAG],
    }),
  }),
});

export default assetApi;

export const {
  useGetAlbumsQuery,
  useGetGoodyBagQuery,
  useGetItemsQuery,
  useGetGoodyBagsForAdvisorQuery,
  useLazyGetGoodyBagReviewsQuery,
  useLazyGetGoodyBagItemsQuery,
  useGoodyBagSearchMutation,
  usePurchaseGoodyBagMutation,
  usePinGoodyBagMutation,
  useUnpinGoodyBagMutation,
  useDeleteGoodyBagMutation,
} = assetApi;
