import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import ModalsContext from 'app/contexts/ModalsContext';
import ActionRequiredModal from 'app/components/modals/ActionRequiredModal';
import ManageCardsModalContainer from 'app/containers/modals/ManageCardsModalContainer';
import LikeButton from 'app/components/posts/postFooter/LikeButton';
import PostLikesModalContainer from './modals/PostLikesModalContainer';

import {
  useGetFollowQuery,
  useFollowAdvisorMutation,
  usePerformAddLikeMutation,
  usePerformRemoveLikeMutation,
} from 'app/api/feedsApi';

import { useGetAppConfigQuery } from 'app/api/mainApi';

const LikeButtonContainer = ({ post, locked }) => {
  const [liked, setLiked] = useState(Boolean(post.has_like));
  const [likesCount, setLikesCount] = useState(post.likes_count);

  const [likePost] = usePerformAddLikeMutation();
  const [unlikePost] = usePerformRemoveLikeMutation();
  const [followAdvisor, { status: createFollowStatus }] = useFollowAdvisorMutation();
  const { openModal } = useContext(ModalsContext);
  const appConfig = useGetAppConfigQuery();
  const follow = useGetFollowQuery({ advisorId: post.account.main_user_id })?.data;
  const currentUser = appConfig?.data?.current_user;
  const hasCardOnFile = Object.keys(currentUser?.default_card || {}).length > 0;
  const myOwnPost = currentUser?.id === post?.account?.main_user_id;

  const handleUnlike = () => {
    setLiked(false);
    setLikesCount(likesCount - 1);

    unlikePost({ postId: post.id });
  };

  const handleLike = () => {
    setLiked(true);
    setLikesCount(likesCount + 1);

    likePost({ postId: post.id });
  };

  const handleLikeAction = () => (
    liked ? handleUnlike() : handleLike()
  );

  const createFollowAndLikeAction = () => {
    followAdvisor({ advisorId: post.account.main_user_id, postId: post.id, refetchPost: true });
  };

  const addCardClickAction = () => {
    openModal({
      component: ManageCardsModalContainer,
      props: {
        afterCloseAction: createFollowAndLikeAction,
      },
    });
  };

  const triggerCardModalFlow = () => {
    openModal({
      component: ActionRequiredModal,
      props: {
        onClickAction: addCardClickAction,
        copy: {
          headerCopy: 'Follow this Flirt?',
          bodyCopy: `You must follow ${post.account.login}
              and have a credit card on file to leave comments.`,
          submitButtonCopy: 'Add Card',
        },
      },
    });
  };

  const triggerFollowModalFlow = () => {
    openModal({
      component: ActionRequiredModal,
      props: {
        onClickAction: createFollowAndLikeAction,
        copy: {
          headerCopy: 'Follow this Flirt?',
          bodyCopy: `You must follow ${post.account.login}
              to like this post.`,
          submitButtonCopy: 'Follow this Flirt',
        },
      },
    });
  };

  const triggerValidationModalFlow = () => (
    hasCardOnFile ? triggerFollowModalFlow() : triggerCardModalFlow()
  );

  const likeButtonClickAction = () => (
    follow?.active ? handleLikeAction() : triggerValidationModalFlow()
  );

  const likeButtonClick = (e) => {
    e.preventDefault();

    if (locked) return false;

    if (myOwnPost) {
      openModal({
        component: PostLikesModalContainer,
        props: { post },
        modalContentStyles: { maxWidth: '290px' },
      });
    } else {
      likeButtonClickAction();
    }

    return true;
  };

  // detect whether the automatic follow has occurred, and if so, resume
  // handling the Like action.
  useEffect(() => {
    if (createFollowStatus === 'fulfilled') handleLikeAction();
  }, [createFollowStatus]);

  return (
    <LikeButton
      locked={locked}
      likeButtonClick={likeButtonClick}
      liked={liked}
      currentUser={currentUser}
    />
  );
};

LikeButtonContainer.defaultProps = {
  post: null,
  locked: true,
};

LikeButtonContainer.propTypes = {
  post: PropTypes.object,
  locked: PropTypes.bool,
};
export default LikeButtonContainer;
