import React, { useState, useEffect } from 'react';

import { Stack, Typography, Button, DialogContent, IconButton } from '@mui/material';
import AutoFillableTextField from 'app/components/shared/AutoFillableTextField';
import { useCreatePasswordResetMutation } from 'app/api/mainApi';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import useRecaptcha from 'app/hooks/useRecaptcha';

const ForgotPasswordDialog = ({ type, closeDialog }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [sendPasswordReset, { data: passwordResetData, error: passwordResetError }  ] = useCreatePasswordResetMutation();

  const { executeRecaptcha, recaptchaComponent } = useRecaptcha();

  const label = 'Email Address';

  const emailRegex =  /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

  const displayError = error || passwordResetError?.data?.error;

  const validateEmailAndSend = (captchaToken) => {
    if (!email.match(emailRegex)) {
      setError('Please enter a valid email address.');
    } else {
      sendPasswordReset({ email, recaptcha_token: captchaToken });
    }
  }

  const onChange = (token) => {
    validateEmailAndSend(token);
  }

  const onError = () => {
    setError('There was an error with the reCAPTCHA. Please try again.');
  }

  // if we have a loginData object, we should redirect to the URL it contains
  useEffect(() => {
    if (passwordResetData) {
      let redirectTo = '/login';
      switch(passwordResetData.redirectTo) {
        case 'verify_payer_id':
          redirectTo = 'verify_account?type=payer_id';
          break;
        case 'verify_card':
          redirectTo = 'verify_account?type=card';
          break;
        case 'reset_password_complete':
          redirectTo = 'reset_password_complete';
          break;
        default:
          redirectTo = 'login';
      }
      navigate(redirectTo);
    }
  }, [passwordResetData]);

  let title = 'Forgot Password';
  let wording = 'We will send you an email with instructions on how to reset your password.';

  return (
    <>
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
          <a href="/">
            <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
          </a>
          <Typography variant="h3">
            { title }
          </Typography>
          <Typography variant="body1" align="center">
            { wording }
          </Typography>
        </Stack>
        {
          displayError && (
            <Stack mb={3}>
              <Typography variant="body3" style={{color: '#d32f2f'}}>
                <div dangerouslySetInnerHTML={{__html: displayError}} />
              </Typography>
            </Stack>
          )
        }

        <Stack direction="column" spacing={3}>
          <AutoFillableTextField
            name="email"
            label={label}
            type="email"
            value={email}
            setValue={setEmail}
            onKeyPress={(e) => { if (e.key === 'Enter') executeRecaptcha() }}
          />
          <Stack direction="row" justifyContent="center" mb={3} mt={2}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={executeRecaptcha}
              disabled={!email}
            >
              Send
            </Button>
          </Stack>
        </Stack>
        { recaptchaComponent(onChange, onError) }
      </DialogContent>
    </>
  );
};

export default ForgotPasswordDialog;
