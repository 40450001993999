import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Unstable_Grid2';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import CategoryBreadcrumbs from 'app/components/categories/CategoryBreadcrumbs';

const StickyHeader = ({
  breadcrumbs,
  tabs,
  currentTab,
  handleTabChange,
  sorts,
  currentSort,
  handleSortChange,
}) => (
  <Box
    sx={{
      position: 'sticky',
      top: '0',
      backgroundColor: 'white',
      padding: '20px',
      zIndex: 2,
    }}
  >
    <CategoryBreadcrumbs breadcrumbs={breadcrumbs} />
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} sm={9}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={12} sm={3} container justifyContent="flex-end">
        {currentTab !== 3 && (
          <FormControl sx={{ zIndex: 10, minWidth: 120 }}>
            <InputLabel id="sort-by-label">Sort by</InputLabel>
            <Select
              labelId="sort-by-label"
              value={currentSort}
              label="Sort by"
              onChange={handleSortChange}
            >
              {sorts.map((sort) => (
                <MenuItem key={sort.value} value={sort.value}>
                  {sort.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
    </Grid>
  </Box>
);

StickyHeader.propTypes = {
  breadcrumbs: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  currentTab: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  sorts: PropTypes.array.isRequired,
  currentSort: PropTypes.number.isRequired,
  handleSortChange: PropTypes.func.isRequired,
};

export default StickyHeader;
