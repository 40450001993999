import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import GoodyBagShape from 'app/shapes/GoodyBagShape';

import GoodyBagRatingSummary from 'app/components/goodyBagDetailsDrawer/GoodyBagRatingSummary';

const GoodyBagMeta = ({ advisor, goodyBag, openRatingsDrawer }) => {
  // TODO: Can we move these classes into an override? or Component
  return (
    <Fragment>
      <Grid item xs={12} sx={{ p: 2 }} className="title">
        {goodyBag.title}
      </Grid>
      <Grid item sm={8} xs={7}>
        <Grid container>
          <Grid item xs={12} sx={{ p: 2, pt: 0, pb: 0 }} className="goodie-by">
            <div className="ellipsis">
              by&nbsp;
              <a href={`/${advisor.login}`}>{advisor.login}</a>
            </div>
          </Grid>
          <GoodyBagRatingSummary goodyBag={goodyBag} openRatingsDrawer={openRatingsDrawer} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

GoodyBagMeta.defaultProps = {
  advisor: {},
  goodyBag: {},
};

GoodyBagMeta.propTypes = {
  advisor: PropTypes.object,
  goodyBag: GoodyBagShape,
  openRatingsDrawer: PropTypes.func.isRequired,
};

export default GoodyBagMeta;
