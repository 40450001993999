import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

const StyledCard = styled(Card)({
  boxShadow: 'none',
  padding: '0px',
  borderRadius: '0px',
  backgroundColor: '#fff',
  color: '#000',
  border: '1px solid #ccc',
});

export default StyledCard;
