import React from 'react';
import { PropTypes } from 'prop-types';
import AuthenticatedLink from 'app/components/shared/AuthenticatedLink';

const LikeButton = ({ locked, likeButtonClick, liked, currentUser }) => {
  return (
    <div data-test-id="like-button" className={`${locked ? 'locked-footer' : ''}`}>
      <AuthenticatedLink
        title="Like"
        role="button"
        tabIndex="0"
        currentUser={currentUser}
        style={locked ? null : { cursor: 'pointer' }}
        onClick={likeButtonClick}
      >
        <div className="row middle-xs">
          <div>
            <img
              className={liked ? 'active-like' : null}
              src="/plus_frontend/assets/svg/ico-favorite.svg"
              title="Favorite"
              alt="Favorite"
            />
          </div>
        </div>
      </AuthenticatedLink>
    </div>
  );
};

LikeButton.defaultProps = {
  currentUser: null,
};

LikeButton.propTypes = {
  locked: PropTypes.bool.isRequired,
  likeButtonClick: PropTypes.func.isRequired,
  liked: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
};

export default LikeButton;
