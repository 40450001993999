import React from 'react';
import CallListingShape from 'app/shapes/CallListingShape';

import {
  Box,
  Typography,
} from '@mui/material';

import Connecting from 'app/components/call/callInProgress/Icons/Connecting';

const AdvisorAnsweredStep = ({ listing }) => {
  return (
    <Box
      textAlign="center"
      mt={3}
    >
      <Typography variant="h3">
        Please wait while we call&nbsp;
        { listing.login }
      </Typography>
      <Box mt={3} mb={3}>
        <Connecting />
      </Box>
      <Typography variant="body1" gutterBottom>
        You won&apos;t be charged until the conversation begins.
      </Typography>
    </Box>
  );
};

AdvisorAnsweredStep.propTypes = {
  listing: CallListingShape.isRequired,
};

export default AdvisorAnsweredStep;
