import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import GoodyBagShape from 'app/shapes/GoodyBagShape';

import { toCurrency } from 'app/helpers/currencyHelpers';

import AuthenticatedLink from 'app/components/shared/AuthenticatedLink';

const PurchaseGoodyBag = ({ goodyBag, closeDrawer, onBuyGoodyBagButtonClick, returnUrl, currentUser }) => {
  // TODO: Can we move these classes into an override? or Component maybe more variants?
  return (
    <Grid item sm={4} xs={5} className="pull-right">
      <Grid container sx={{ m: '0 auto', width: '120px', textAlign: 'center' }}>
        <Grid item xs={12}>
          <AuthenticatedLink
            role="dialog"
            returnUrl={returnUrl}
            currentUser={currentUser}
            onClick={(event) => {
              closeDrawer(event);
              onBuyGoodyBagButtonClick();
            }}
          >
            <Button
              variant="contained"
              size="medium"
              color="secondary"
            >
              Buy Now
            </Button>
          </AuthenticatedLink>
        </Grid>
        <Grid item xs={12} sx={{ pt: 1 }} className="price">
          {toCurrency(goodyBag.price)}
        </Grid>
      </Grid>
    </Grid>
  );
};

PurchaseGoodyBag.defaultProps = {
  goodyBag: {},
  currentUser: null,
};

PurchaseGoodyBag.propTypes = {
  goodyBag: GoodyBagShape,
  closeDrawer: PropTypes.func.isRequired,
  onBuyGoodyBagButtonClick: PropTypes.func.isRequired,
  returnUrl: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
};

export default PurchaseGoodyBag;
