import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import DialogsContext from 'app/contexts/DialogsContext';
import ListingSearch from 'app/components/ListingSearch';
import ListingSearchFiltersContainer from 'app/containers/modals/ListingSearchFiltersContainer';

import {
  useGetTopLevelCategoriesQuery,
  useLazyGetListingSearchResultsQuery,
} from 'app/api/mainApi';

const TABS = {
  live: 1,
  recorded: 2,
  chat: 3,
};

const CATEGORY_MAP = {
  Women: 'Find Women',
  Men: 'Find Men',
  Trans: 'Find Transgender',
};

const PRICE_MAPPING = {
  'Under $1': 'price_0',
  '$1-$1.99': 'price_1',
  '$2-$2.99': 'price_2',
  '$3-$3.99': 'price_3',
  '$4 or more': 'price_4',
};

const ListingSearchContainer = () => {
  const [searchQueryText, setSearchQueryText] = useState('');
  const [searchError, setSearchError] = useState('');
  const [showTabs, setShowTabs] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('Women');
  const [categoryId, setCategoryId] = useState(null);
  const [typeId, setTypeId] = useState(TABS.live);
  const [sort, setSort] = useState(1);
  const [filters, setFilters] = useState([]);
  const allPriceOptions = Object.keys(PRICE_MAPPING);
  const [selectedPrices, setSelectedPrices] = useState(allPriceOptions);
  const [limitToAvailable, setLimitToAvailable] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [forceReset, setForceReset] = useState(0);
  const [page, setPage] = useState(1);
  const { openDialog } = useContext(DialogsContext);
  const { data: topLevelCategoriesResponse } = useGetTopLevelCategoriesQuery();
  const topLevelCategories = topLevelCategoriesResponse?.categories || [];
  const activeFilterCount = useMemo(() => (
    filtersApplied ? (filters.length + (limitToAvailable ? 1 : 0)) : 0
  ), [filtersApplied, filters.length, limitToAvailable]);
  const topRef = useRef(null);

  const [trigger, { data: listingSearchResponse, isLoading }] = useLazyGetListingSearchResultsQuery();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const triggerSearch = () => {
    const trimmedSearchQuery = searchQueryText.trim();
    const hasSearchText = trimmedSearchQuery.length > 0;
    const hasFilters = filtersApplied && filters.length > 0;
    const invalidSearchText = hasSearchText && trimmedSearchQuery.length < 3;
    const invalidFilters = hasFilters && trimmedSearchQuery.length < 3;

    if (invalidSearchText || invalidFilters) {
      setSearchError('Please enter at least 3 characters.');
      return;
    }

    if (searchError) {
      setSearchError('');
    }

    const shouldShowTabs = hasSearchText || hasFilters;
    setShowTabs(shouldShowTabs);

    const advancedSearch = activeFilterCount > 0 ? 1 : 0;

    trigger({
      searchQueryText: trimmedSearchQuery,
      advancedSearch,
      filters: filtersApplied ? filters : [],
      limitToAvailable: filtersApplied ? limitToAvailable : false,
      categoryId,
      type: typeId,
      sort,
      page,
    });
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchQueryText(value);

    if (searchError && value.trim().length >= 3) {
      setSearchError('');
    }
  };

  const handleSearchSubmit = (event) => {
    if (event.key !== 'Enter') return;

    const trimmedSearchQuery = searchQueryText.trim();

    if (trimmedSearchQuery.length > 0 && trimmedSearchQuery.length < 3) {
      setSearchError('Please enter at least 3 characters.');
      return;
    }

    setSearchError('');
    setPage(1);

    triggerSearch();

    // Blur the input to close the keyboard on mobile devices
    if (event.target && typeof event.target.blur === 'function') {
      event.target.blur();
    }
  };

  const handleCategoryClick = (categoryValue) => {
    setPage(1);
    setSelectedCategory(categoryValue);
  };

  const resetFilters = () => {
    setFilters([]);
    setSelectedPrices(allPriceOptions);
    setLimitToAvailable(false);
    setFiltersApplied(false);
  };

  const handleFiltersApplied = (newSelectedPrices, newLimitToAvailable) => {
    setSelectedPrices(newSelectedPrices);
    setLimitToAvailable(newLimitToAvailable);

    const mappedPrices = newSelectedPrices.map(price => PRICE_MAPPING[price]);

    setFilters(mappedPrices);
    setFiltersApplied(true);
    setPage(1);
  };

  const handleClearClick = () => {
    setSearchQueryText('');
    resetFilters();
    setPage(1);
    // Incrementing provides a state change to trigger the useEffect without the
    // need to care about resetting a boolen value.
    setForceReset(prev => prev + 1);
  };

  const handleFilterClick = () => {
    setFiltersApplied(false);

    openDialog({
      component: ListingSearchFiltersContainer,
      props: {
        allPriceOptions,
        initialSelectedPrices: selectedPrices,
        initialLimitToAvailable: limitToAvailable,
        resetFilters,
        onFiltersApplied: handleFiltersApplied,
      },
    });
  };

  const showClearIcon = searchQueryText.trim().length > 0 || filtersApplied;

  const handleSortChange = (sort) => {
    setSort(Number(sort));
    setPage(1);
  };

  useEffect(() => {
    if (topLevelCategories && selectedCategory) {
      const categoryName = CATEGORY_MAP[selectedCategory];
      const matchedCategory = topLevelCategories.find((cat) => cat.name === categoryName);

      if (matchedCategory && matchedCategory.id !== categoryId) {
        setCategoryId(matchedCategory.id);
      }
    }
  }, [categoryId, selectedCategory, topLevelCategories]);

  useEffect(() => {
    if (categoryId !== null) {
      triggerSearch();
    }
  }, [
    categoryId,
    typeId,
    sort,
    page,
    filtersApplied,
    forceReset,
  ]);

  useEffect(() => {
    if (listingSearchResponse) {
      if (topRef.current) {
        topRef.current.scrollTo({
          top: 0,
          behavior: 'auto',
        });
      }
    }
  }, [page, listingSearchResponse]);

  return (
    <ListingSearch
      ref={topRef}
      activeFilterCount={activeFilterCount}
      searchQueryText={searchQueryText}
      searchError={searchError}
      selectedCategory={selectedCategory}
      showClearIcon={showClearIcon}
      typeId={typeId}
      setTypeId={setTypeId}
      page={page}
      setPage={setPage}
      handleSearchChange={handleSearchChange}
      handleSearchSubmit={handleSearchSubmit}
      handleClearClick={handleClearClick}
      handleFilterClick={handleFilterClick}
      handleCategoryClick={handleCategoryClick}
      handleSortChange={handleSortChange}
      sort={sort}
      isLoading={isLoading}
      TABS={TABS}
      showTabs={showTabs}
      searchResults={listingSearchResponse?.search_results}
      handlePageChange={handlePageChange}
    />
  );
};

export default ListingSearchContainer;
