import React from 'react';
import { PropTypes } from 'prop-types';
import { Box, Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';

const BoxPagination = styled(Box)(() => ({
  '&.MuiBox-root': {
    margin: '0px', // adjust this as needed
    padding: '0px',
    '& nav.MuiPagination-root': {
      height: 'auto',
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      borderRight: 'none',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '3.0em',
    },
  },
}));

const RoundedPagination = ({ page, count, onChange }) => {
  const localOnChange = (e, value) => {
    const bodyContents = document.getElementsByClassName('body-content');

    if (bodyContents.length > 0) {
      bodyContents[0].scrollTop = 0;
    }

    onChange(e, value);
  };

  if (count <= 1) {
    return null;
  }

  return (
    <BoxPagination>
      <Pagination
        size="large"
        siblingCount={0}
        page={page}
        count={count}
        shape="rounded"
        onChange={localOnChange}
      />
    </BoxPagination>
  );
};

RoundedPagination.defaultProps = {
  count: 0,
  page: 1,
};

RoundedPagination.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default RoundedPagination;
