import React from 'react';
import Tooltip from 'react-tooltip-lite';
import { PropTypes } from 'prop-types';

import withTooltip from 'app/hoc/withTooltip';

const GoodyBagActionMenu = ({
  actions,
  toggleTip,
  tipOpen,
  setButtonRef,
  setTipContentRef,
}) => {
  return (
    <Tooltip
      content={(
        <div
          className="nfp-tooltip"
          id="actionmenu"
          style={{ width: '100px' }}
          ref={(el) => setTipContentRef(el)}
        >
          <div className="row nowrap-xs">
            <div className="col-xs-10">
              <div className="menu-links">
                <div>
                  { actions.map((action) => (
                    <span key={action.label}>
                      <a
                        role="link"
                        onClick={() => { action.onClick(toggleTip); }}
                        tabIndex={0}
                      >
                        {action.label}
                      </a>
                      <br />
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-xs-2 end-xs">
              <a
                role="link"
                tabIndex={0}
                onClick={toggleTip}
              >
                <img
                  src="/plus_frontend/assets/svg/x-close.svg"
                  className="marginlftminus5"
                  alt="close"
                />
              </a>
            </div>
          </div>
        </div>
      )}
      isOpen={tipOpen}
      tagName="span"
      direction="down"
      forceDirection
    >
      <a
        role="link"
        tabIndex={0}
        ref={(el) => setButtonRef(el)}
        onClick={toggleTip}
      >
        <img
          src="/plus_frontend/assets/svg/ico-more-menu.svg"
          alt="More"
          className="icon-more-goody-bag"
        />
      </a>
    </Tooltip>
  );
};

GoodyBagActionMenu.propTypes = {
  actions: PropTypes.array.isRequired,
  toggleTip: PropTypes.func.isRequired,
  tipOpen: PropTypes.bool.isRequired,
  setButtonRef: PropTypes.func.isRequired,
  setTipContentRef: PropTypes.func.isRequired,
};

export default withTooltip(GoodyBagActionMenu);
