import PropTypes from 'prop-types';

const CallStatusShape = PropTypes.shape({
  conference_id: PropTypes.number,
  message: PropTypes.string,
  status: PropTypes.string,
  ngtoken: PropTypes.number,
  bucket_transaction_id: PropTypes.number,
});

export default CallStatusShape;
