import React from 'react';
import { PropTypes } from 'prop-types';
import AuthenticatedLink from '../../shared/AuthenticatedLink';

const SubscriptionButton = ({
  buttonClass,
  callToAction,
  label,
  price,
  period,
  secondaryLabel,
  secondaryDate,
  isFetching,
  currentUser,
}) => {
  return (
    <>
      <div
        className={`container-bundle subscription-btn-wrapper ${buttonClass} ${isFetching ? 'submitting' : ''}`}
      >
        <AuthenticatedLink
          className="subscription-btn"
          role="link"
          tabIndex={0}
          currentUser={currentUser}
          onClick={() => {
            if (isFetching) { return; }
            if (typeof callToAction === 'function') callToAction();
          }}
        >
          <div className="row middle-xs">
            <div className="col-xs-6">
              <div>
                {label}
                &nbsp;
                <span className="small-text" />
              </div>
            </div>
            <div className="col-xs-6 end-xs">
              <div className="row middle-xs end-xs">
                {price}
                <span className="small-text">
                  &nbsp;
                  {period}
                </span>
              </div>
            </div>
          </div>
        </AuthenticatedLink>
      </div>
      {secondaryLabel && secondaryDate && (
        <div className="container-renew">
          <div className="row middle-xs">
            <div className="col-xs-7 start-xs">
              {secondaryLabel}
            </div>
            <div className="col-xs-5 end-xs">
              {secondaryDate}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

SubscriptionButton.defaultProps = {
  secondaryLabel: null,
  secondaryDate: null,
  callToAction: null,
  isFetching: false,
  currentUser: null,
};

SubscriptionButton.propTypes = {
  buttonClass: PropTypes.string.isRequired,
  callToAction: PropTypes.func,
  label: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string,
  secondaryDate: PropTypes.string,
  isFetching: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default SubscriptionButton;
